import { X } from 'heroicons-react';
import React from 'react'
import Chart from "react-apexcharts"





function plotLineChart(xInputArray, yInputArray, title,selectedChartType, yMin, yMax){
  const options = {
    chart: {
      stacked: false,
      zoom: {
        type: 'x',
        enabled: true,
        autoScaleYaxis: true
      },
      toolbar: {
        autoSelected: 'zoom'
      }
    },
    dataLabels: {
      enabled: false
    },
    markers: {
      size: 0,
    },
    title: {
  
      text: title,
      align: 'left',
      style:{color:"white"}
    },
    fill: {
      type: 'gradient',
      gradient: {
        shadeIntensity: 1,
        inverseColors: false,
        opacityFrom: 1,
        opacityTo: 0.5,
        stops: [0, 90, 100]
      },
    },
    yaxis: {
      min: Math.min.apply(null,yInputArray)*yMin,
      max: Math.max.apply(null,yInputArray)*yMax,  
      labels: {
        formatter: function (val) {
          return parseInt(val).toLocaleString("en-US");
        },
        style:{colors: "white"}
      },
      title: {
        style:{color:"white"}
      },
    },
    xaxis: {
      categories: xInputArray,
      labels:{style:{colors: "white"}}
      //type: 'datetime',
    },
    tooltip: {
      shared: false,
      y: {
        formatter: function (val) {
          return (val).toLocaleString("en-US")
        }
      }
    }
  }
  return(
    <>
    <Chart options={options} 
      series={[{
                name: title,
                data: yInputArray
              }]}

      height="300" type="area" />
    </>
  )

}




function StandardLineChart({xList,yList, title,selectedChartType, yMin, yMax}) {

/*     console.log("DATES:",xList)
    console.log("INSTALLS:",yList) */

  return (
    <div className='p-10 bg-cyan-900 w-full'>
 
      {plotLineChart(xList, yList, title, "",yMin, yMax)}

    </div>
    
  )
}

export default StandardLineChart