import React from 'react'
import { useEffect,useState, useRef } from 'react'
import { Calendar, DateRangePicker, DateRange } from 'react-date-range'
import format from 'date-fns/format'


import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { addDays } from 'date-fns';
import SelectChartDisplay from './../SelectChartDisplay';
import {BrowserView, MobileView, isBrowser, isMobile} from 'react-device-detect';



function FromToBase({setFromToDates}) {

  const [range, setRange] = useState([
    {
      startDate : new Date("2023-01-01T00:00:00"),
      endDate : addDays(new Date(), 0),
      key : "selection"

    }
  ])
  const [open, setOpen] = useState(false)
  const refOne = useRef(null)


  useEffect(() => {
    document.addEventListener("keydown", hideOnEscape, true)
    document.addEventListener("click", hideOnClickOutside, true)
  }, [])

  useEffect(() => {
    
    setFromToDates(range)
  }, [range])



  const hideOnEscape = (e) =>{
    if(e.key === "Escape"){
      setOpen(false)
    }
  }
  const hideOnClickOutside = (e) =>{
    if(refOne.current && !refOne.current.contains(e.target)){
      setOpen(false)
    }
  }

/*   console.log("RANGEval1", range[0].startDate)
  console.log("RANGEval2", range[0].endDate) */
  return (
    <div className='calendarWrap flex  justify-center'>
      <div className='bg-slate-700 py-4 overflow-hidden text-2xl my-1 rounded-md'>
          <div className=''>
              <input className='font-bold text-3xl bg-slate-700  text-white text-center focus:outline-none'
                readOnly
                value = {"Selected range"}
                onClick={() => setOpen(open => !open)}
              />
          </div>
          <input
          value = {`${format(range[0].startDate, "dd/MM/yyyy")}  -  ${format(range[0].endDate, "dd/MM/yyyy")}`}
          readOnly
          className='inputBox py-2 text-white bg-cyan-600 rounded-lg px-2 my-2 font-bold border-sky-900 focus:outline-none'
          onClick={() => setOpen(open => !open)}
          />
          <div ref={refOne}>
              {open &&
               <>       
                        {isBrowser ?  
                                      <DateRangePicker
                              onChange = {item => setRange([item.selection])} 
                              editableDateInputs={true}
                              moveRangeOnFirstSelection={false}
                              ranges={range}
                              months={1}
                              direction="vertical"
                              className = "calendarElement"
                              rangeColors={['#334155']}
                              format="DD-MM-YYYY"
                              minDate={new Date("2023-01-01")}
                              maxDate={new Date()}
                              />
                        :
                                      <DateRange
                              onChange = {item => setRange([item.selection])} 
                              editableDateInputs={true}
                              moveRangeOnFirstSelection={false}
                              ranges={range}
                              months={1}
                              direction="vertical"
                              className = "calendarElement"
                              rangeColors={['#334155']}
                              format="DD-MM-YYYY"
                              minDate={new Date("2023-01-01")}
                              maxDate={new Date()}
                              />
                        }
                    

                </>
              }
          </div>
      </div>

    </div>

  )
}

export default FromToBase