import React from "react";
import { useState, useEffect } from "react";
import { Link } from 'react-router-dom';

export default function SuccessDeleteModal({updateCount, successBool}) {

  const [showModal, setShowModal] = useState(false);
  useEffect(() => {
        if(successBool!=="INITSTATE"){
        //console.log("successbool", successBool)
        setShowModal(true)
        }
    
}, [successBool])
  //console.log("SUCCESSBOOL", successBool)

  function handleSubmit(bool){
    setShowModal(bool)
  }

  return (
    <>

    {showModal ? ( 
        successBool ? ( 
            <>
            <div
                className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
            >
                <div className="relative w-auto my-6 mx-auto max-w-3xl">
                {/*content*/}
                <div className=" rounded-md shadow-md border-2 border-black relative flex flex-col w-full bg-cyan-700 outline-none focus:outline-none">
                    {/*header*/}
                    <div className="flex items-start justify-between p-5 border-b border-solid border-cyan-500 rounded-t">
                    <h3 className="text-xl text-cyan-300 font-bold">
                        SUCCESS - DASHBOARD DELETED
                    </h3>
                    <button
                        className="p-1 ml-auto bg-transparent border-0 text-white opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                        onClick={() => handleSubmit(false)}
                    >
                        <span className="bg-transparent text-white  h-6 w-6 text-2xl block outline-none focus:outline-none">
                        ×
                        </span>
                    </button>
                    </div>
                    {/*body*/}
                    <div className="relative p-6 flex-auto bg-gray-100">
                    <p className="my-4 text-cyan-800 ">
                        Dashboard has successfully been deleted.
                    </p>
                    </div>
                    {/*footer*/}
                    <div className="flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
                        
                    <Link
                        className="bg-cyan-500 text-white font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                        type="button"
                        to="/"
                        onClick={() => handleSubmit(false)}
                    >
                        Close
                    </Link>
                    </div>
                </div>
                </div>
            </div>
            <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
        )
        : (
            <>
                <div
                    className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
                >
                    <div className="relative w-auto my-6 mx-auto max-w-3xl">
                    {/*content*/}
                    <div className=" rounded-md shadow-md border-2 border-black relative flex flex-col w-full bg-rose-700 outline-none focus:outline-none">
                        {/*header*/}
                        <div className="flex items-start justify-between p-5 border-b border-solid border-red-500 rounded-t">
                        <h3 className="text-xl text-rose-300 font-bold">
                            Failed to delete dashboard
                        </h3>
                        <button
                            className="p-1 ml-auto bg-transparent border-0 text-white opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                            onClick={() => handleSubmit(false)}
                        >
                            <span className="bg-transparent text-white  h-6 w-6 text-2xl block outline-none focus:outline-none">
                            ×
                            </span>
                        </button>
                        </div>
                        {/*body*/}
                        <div className="relative p-6 flex-auto bg-gray-100">
                        <p className="my-4 text-cyan-800 ">
                            Check your internet connection.
                        </p>
                        </div>
                        {/*footer*/}
                        <div className="flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
                            
                        <Link
                            className="bg-red-500 text-white font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                            type="button"
                            onClick={() => handleSubmit(false)}
                        >
                            Close
                        </Link>
                        </div>
                    </div>
                    </div>
                </div>
                <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
            </>
        )
        
        )
       : <div></div>}
    </>
  );
}



