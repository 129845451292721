import { useState } from 'react'
import { RadioGroup } from '@headlessui/react'

const memoryOptions = [
  { name: 'Unstacked', inStock: true },
  { name: 'Stacked', inStock: true },
]

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

function StackedUnstackedSelection({setStackedUnstacked}) {

    //lage usestate for installs, reviews, stjerner, etc.
    const [mem, setMem] = useState(memoryOptions[0])
    setStackedUnstacked(mem.name)  
    
    return (
      <div className='px-10 bg-cyan-700 pt-2 pb-2 border-t-2 bortder-b-2'>
        <RadioGroup value={mem} onChange={setMem} className="mt-2 my-2">
          <RadioGroup.Label className="sr-only"> Choose a memory option </RadioGroup.Label>
            <div className="grid grid-cols-2 gap-24 sm:grid-cols-2">
                {memoryOptions.map((option) => (
                <RadioGroup.Option
                    key={option.name}
                    value={option}
                    className={({ active, checked }) =>
                    classNames(
                        option.inStock ? 'cursor-pointer focus:outline-none' : 'opacity-25 cursor-not-allowed',
                        active ? 'ring-2 ring-offset-2 ring-slate-500' : '',
                        checked
                        ? 'bg-slate-700 border-transparent text-white hover:bg-slate-700'
                        : 'bg-white border-gray-200 text-gray-900 hover:bg-gray-50',
                        'border rounded-md py-1 px-3 flex items-center justify-center text-sm font-medium uppercase sm:flex-1'
                    )
                    }
                    disabled={!option.inStock}
                >
                    <RadioGroup.Label as="span">{option.name}</RadioGroup.Label>
                </RadioGroup.Option>
                ))}
            </div>
        </RadioGroup>
      </div>
    )
}

export default StackedUnstackedSelection