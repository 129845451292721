import React from 'react'
import Footer from '../Footer'
import { Disclosure } from '@headlessui/react'
import { ChevronDownIcon } from '@heroicons/react/24/outline'
import FromToBase from '../fromTo/FromToBase'


const faqs = [
  {
    question: "Why don't you provide data from the Apple App Store?",
    answer:
      "It's on our to-do-list. We have created this site just for fun and has not"
      +" had the time or resources to incorporate App Store data yet.",
  },
  {
    question: 'Why is the data (e.g. daily installs) so "volatile"?',
    answer:
      "App data from Google is updated at somewhat arbitrary times with values that aren't necessarily with by-the-hour precision."+
      " This means that  Once you start to track an app, we provide you with the raw data."
      + " After a week, we utilize a smoothing algorithm to provide you with somewhat better estimates of daily data.",
  },
  {
    question: 'Why can daily ratings, reviews etc. be negative?',
    answer:
      'At times, Google removes ratings, reviews etc. that they deem likely to be "fake"'
      + " - from bots etc. trying to either hurt competitors or prop up own apps. Users can also delete reviews and ratings themselves."
  },
  {
    question: 'Can I trust your data?',
    answer:
      "Our data is based on data from Google Play. We can in no way guarantee that the data is right."+
      " Also, sometimes App data isn't updates regularly, meaning we have to rely on our own predivtive algorithms. These algorithms naturally tend to be less precise than the actual data from Google Play." + 
      " Please read our 'Terms & Privacy' section."
  },
  {
    question: "Why do you provide ratings, reviews etc.? Shouldn't installs be the most interesting metric?",
    answer: "Maybe. For some apps, however, I believe ratings and reviews provide incremental value."
     +" In some cases, apps come preinstalled on phones and tablets, which means the installs are steadily increasing even though the user is not using the app."
     + " reviews and ratings could provide incremental value in determining app engagement."
  },

  // More questions...
]

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}


function firstElement(){


  return(
    <>
        <div className="bg-cyan-700 border-y-2 pt-12 sm:pt-16">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto max-w-4xl text-center">
          <h2 className="text-3xl font-bold tracking-tight text-white sm:text-3xl">
            Use this site to track the apps that you like
          </h2>
          <p className="mt-3 text-xl text-slate-100 font-bold sm:mt-4">
              Create and customize your own dashboards - or feel free to visit our top 100 apps and games - updated daily
          </p>
        </div>
      </div>
      <div className="mt-10 bg-cyan-700 pb-12 sm:pb-16">
        <div className="relative">
          <div className="absolute inset-0 h-1/2 bg-cyan-700" />
          <div className="relative mx-auto max-w-7xl px-6 lg:px-8">
            <div className="mx-auto max-w-4xl">
              <dl className="rounded-lg bg-cyan-500 shadow-lg border-2 sm:grid sm:grid-cols-3">
                <div className="flex flex-col border-b border-gray-100 p-6 text-center sm:border-0 sm:border-r">
                  <dt className="order-2 mt-2 text-lg font-medium leading-6 text-sky-900">Free</dt>
                  <dd className="order-1 text-5xl font-bold tracking-tight text-sky-900">100%</dd>
                </div>
                <div className="flex flex-col border-t border-b border-gray-100 p-6 text-center sm:border-0 sm:border-l sm:border-r">
                  <dt className="order-2 mt-2 text-lg font-medium leading-6 text-sky-900">Updates</dt>
                  <dd className="order-1 text-5xl font-bold tracking-tight text-sky-900">Daily</dd>
                </div>
                <div className="flex flex-col border-t border-gray-100 p-6 text-center sm:border-0 sm:border-l">
                  <dt className="order-2 mt-2 text-lg font-medium leading-6 text-sky-900">Data Privacy</dt>
                  <dd className="order-1 text-5xl font-bold tracking-tight text-sky-900">100%</dd>
                </div>
              </dl>
            </div>
          </div>
        </div>
      </div>
    </div>
        
    </>
  )
}

function secondElement(){
  return(
    <>
            <div className="bg-slate-800 border-b-black">
          <div className="mx-auto max-w-7xl py-12 px-4 text-center sm:px-6 lg:py-16 lg:px-8">
              <h2 className=" font-bold tracking-tight text-gray-900">
                <span className="text-xl block text-cyan-500 sm:text-2xl">Please recommend this site to friends and colleagues of yours if you want to support the work on this page</span>
                <span className="block text-white text:lg sm:text-md">We are happy for any feedback, but please bare in mind that this site is a one-man-project - things could take some time to implement</span>
              </h2>
              <h6 className='text-l font-semibold tracking-tighter'>
              </h6>
          </div>
        </div>
    </>
  )
}



function FAQS() {
  return (
    <>
             <div className="mx-auto max-w-7xl px-6  pb-32 lg:px-8">
            <div className=" mx-auto max-w-3xl divide-y-2 divide-gray-200">
              <h2 className="text-center font-bold tracking-tight text-white text-2xl lg:text-4xl">
                Frequently asked questions
              </h2>
              <dl className="mt-6 space-y-6 divide-y divide-gray-200">
                {faqs.map((faq) => (
                  <Disclosure as="div" key={faq.question} className="pt-6">
                    {({ open }) => (
                      <>
                        <dt className="text-lg">
                          <Disclosure.Button className="flex w-full items-start justify-between text-left text-cyan-500">
                            <span className="font-medium text-cyan-500">{faq.question}</span>
                            <span className="ml-6 flex h-7 items-center">
                              <ChevronDownIcon
                                className={classNames(open ? '-rotate-180' : 'rotate-0', 'h-6 w-6 transform')}
                                aria-hidden="true"
                              />
                            </span>
                          </Disclosure.Button>
                        </dt>
                        <Disclosure.Panel as="dd" className="mt-2 pr-12">
                          <p className="text-base text-gray-100">{faq.answer}</p>
                        </Disclosure.Panel>
                      </>
                    )}
                  </Disclosure>
                ))}
              </dl>
            </div>
          </div>
    
    </>
  )
}




function About() {
  return (
    
  <div>

        
      <div className='bg-slate-800 h-full border-white border-b-2'>
        {firstElement()}
        {secondElement()}
        <FAQS></FAQS>
      </div>
      <Footer></Footer>
  </div>

    
  )
}

export default About