import React from 'react'
import CreateDashboardForm from './CreateDashboardForm'
import Footer from './Footer'

function CreateDashboard() {
  return (
    <>
      <div className='bg-slate-900 border-y-2 pb-96'>
        <div className='flex justify-center mx-auto w-full lg:w-1/2 '>

            <CreateDashboardForm></CreateDashboardForm>
        </div>
      </div>
      <Footer></Footer>
    </>

  )
}

export default CreateDashboard