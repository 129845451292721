import { ArrowUpIcon } from "@heroicons/react/20/solid"


export default function SelectChartDisplay() {
    return (
      <>
        {/*
          This example requires updating your template:
  
          ```
          <html class="h-full">
          <body class="h-full">
          ```
        */}
        <div className="flex min-h-full flex-col bg-gray-50 pt-16 pb-72">
          <main className="mx-auto flex w-full max-w-7xl flex-grow flex-col justify-center px-6 lg:px-8">
            <div className="flex flex-shrink-0 justify-center">
                <ArrowUpIcon className="h-16 w-16 text-cyan-600"/>
            </div>
            <div className="py-16">
              <div className="text-center">
                <p className="text-base font-semibold text-cyan-600">You have not selected a dashboard yet</p>
                <h1 className="mt-2 text-2xl font-bold tracking-tight text-slate-700 md:text-3sxl">Select the "demo" dashboard or create (select) a custom one to get started</h1>
              </div>
            </div>
          </main>
        </div>
      </>
    )
  }
  