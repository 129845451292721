import React from 'react'
import Footer from '../Footer'
import { Disclosure } from '@headlessui/react'
import { ChevronDownIcon } from '@heroicons/react/24/outline'

import FromToBase from '../fromTo/FromToBase';

const faqs = [
  {
    question: "Include App Store data",
    answer:
      "Priority: Medium. Time Required: High",
  },
  {
    question: 'Include data from other stores (e.g. Steam)',
    answer:
      "Priority: Low. Time Required: High",
  },
  {
    question: 'Fix bugs',
    answer: "Priority: High. Time Required: Medium"
  },
  {
    question: "Add additional insights based on currently existings data",
    answer: "Priority: Low. Time Required: Medium"
  },
  {
    question: "Enhance graph selection experience",
    answer: "Priority: High. Time Required: Low"
  },
  {
    question: "Simplify process to add apps to dashboards",
    answer: "Priority: Low. Time Required: High"
  },
  // More questions...
]

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}


function firstElement(){


  return(
    <>
        <div className="bg-cyan-700 border-y-2 pt-12 sm:pt-16">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto max-w-4xl text-center">
          <h2 className="text-3xl font-bold tracking-tight text-white sm:text-3xl">
          Here you can find my intended plans for future updates to this site 
          </h2>
          <p className="mt-3 text-xl text-white font-bold sm:mt-4">
          Please bare in mind that things usually takes quite a bit of time :)
          </p>
          <p className="text-xl text-white font-bold mb-12">
                Also, feel free to contact us if you have any suggestions
          </p>
        </div>
      </div>
      
    </div>
        
    </>
  )
}

function secondElement(){


  return(
    <div className="bg-slate-800 pt-12 sm:pt-16">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto max-w-4xl text-center">
          <h2 className="text-xl font-bold tracking-tight text-cyan-500 sm:text-3xl">
            The currently most prioritized updates can be seen below  
          </h2>
          <p className="mt-3 text-md sm:text-lg text-white font-bold sm:mt-4">
            Note that some updates will take some times, while others will be easy to implement
          </p>

        </div>
      </div>
    </div>
        
  )
}


function FAQS() {
  return (
    <>
             <div className="mx-auto max-w-7xl pb-32 px-6 lg:px-8">
            
            <div className="mx-auto max-w-3xl divide-y-2 divide-gray-200">
              <h2 className="text-center  font-bold tracking-tight text-white text-2xl lg:text-4xl">
                Planned updates
              </h2>
              <dl className="mt-6 space-y-6 divide-y divide-gray-200">
                {faqs.map((faq) => (
                  <Disclosure as="div" key={faq.question} className="pt-6">
                    {({ open }) => (
                      <>
                        <dt className="text-lg">
                          <Disclosure.Button className="flex w-full items-start justify-between text-left text-cyan-500">
                            <span className="font-medium text-cyan-500">{faq.question}</span>
                            <span className="ml-6 flex h-7 items-center">
                              <ChevronDownIcon
                                className={classNames(open ? '-rotate-180' : 'rotate-0', 'h-6 w-6 transform')}
                                aria-hidden="true"
                              />
                            </span>
                          </Disclosure.Button>
                        </dt>
                        <Disclosure.Panel as="dd" className="mt-2 pr-12">
                          <p className="text-base text-gray-100">{faq.answer}</p>
                        </Disclosure.Panel>
                      </>
                    )}
                  </Disclosure>
                ))}
              </dl>
            </div>
          </div>
    
    </>
  )
}




function FutureUpdates() {
  return (
    
  <div>

      {firstElement()}
      {secondElement()}
      
      <div className='bg-slate-800 h-full py-2 border-b-2 border-white'>
          <FAQS></FAQS>
      </div>
      <Footer></Footer>
  </div>

    
  )
}

export default FutureUpdates