import userEvent from '@testing-library/user-event'
import React from 'react'
import { UserAuth } from '../context/AuthContext'
import Footer from './Footer'
import { useEffect } from 'react'
import { setDoc, getDoc, getDocs,collection, doc } from 'firebase/firestore';
import { db } from './../config/firebase';
import { useState } from 'react';
import LoadingSpinner from './LoadingSpinner'
import SuccessfulNotification from './SuccessfulNotification';
import { deleteDoc } from 'firebase/firestore';
import { Navigate,useNavigate } from 'react-router-dom'
import DeactivateAccountModal from './DeactivateAccountModal'


function Account() {
  const {user, logOut} = UserAuth()
  const navigate = useNavigate()

  const [isLoading, setIsLoading] = useState(true)


  const [activityCheckbox, setActivityCheckbox] = useState(false)
  const [mailInfoCheckbox, setMailInfoCheckbox] = useState(false)

  const [originalActivityState, setOriginalActivityState] = useState(false)
  const [originalMailInfoState, setOriginalMailInfoState] = useState(false)

  const [handleSubmitCount, setHandleSubmitCount] = useState(0)
  const [successfulSubmit, setSuccessfulSubmit] = useState(false)

  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [confirmDelete, setConfirmDelete] = useState(false)

  const handleSignOut = async () => {
    try{
        await(logOut())
        navigate("/")
    } catch(error){
        console.log(error)
    }
}

  useEffect(() => {
    const fetchAll = async () => {

        var docRef = await getDoc(doc(db,"users",`${user.email}`, "settings","privacy")).then((snapshot) => {
          //console.log(snapshot.data())
              if (snapshot.data() !== undefined ) {
                //Dersom dokumentet finnes fra før....
                const privacyData = snapshot.data()
                setActivityCheckbox(privacyData.activityCheckbox )
                setMailInfoCheckbox(privacyData.mailInfoCheckbox )

                setOriginalActivityState(privacyData.activityCheckbox)
                setOriginalMailInfoState(privacyData.mailInfoCheckbox)
                //console.log(snapshot)
                //console.log("Document data:", snapshot.data() );
              } else {
                  //Hvis dokumentet for privacy ikke finnes fra før må vi opprette det
                  const privacySettingsDefault = {activityCheckbox: false, mailInfoCheckbox: false}
                  const docRef = doc(db,"users",`${user.email}`, "settings","privacy")
                  setDoc(docRef, privacySettingsDefault)
                    .then(docRef => {
                      console.log("Document has been added successfully")
                    })
                    .catch(error => {
                        console.log(error);
                    })

              }
        }).catch((error) => {
          console.log("Error getting document:", error);
        });

        setIsLoading(false)
        
        }       
    fetchAll()
  },[user.email, handleSubmitCount])





  function handleSubmit(){
    const selectedPrivacySettings =  {activityCheckbox: activityCheckbox, mailInfoCheckbox: mailInfoCheckbox}
    const docRef = doc(db,"users",`${user.email}`, "settings","privacy")
    setDoc(docRef, selectedPrivacySettings)
    .then(docRef => {
      //console.log("Document has been added successfully")
      setHandleSubmitCount(handleSubmitCount+1)
      setSuccessfulSubmit(true)
    })
    .catch(error => {
        console.log(error);
    })
  }

  function boolCheckboxText(prop){
    if (prop){
      return(<>
                <div className='font-bold bg-emerald-500 text-white p-2 rounded-lg'>This box is currently checked in our system</div>
             </>)
    }else{
      return(<>
                <div className='bg-rose-600 font-bold text-white p-2 rounded-lg'>This box is currently unchecked in our system</div>
            </>)
    }
  }

  function notification(){
    if(successfulSubmit==true){
        return(
          <SuccessfulNotification successfulSubmit={successfulSubmit} setSuccessfulSubmit={setSuccessfulSubmit}></SuccessfulNotification>
        )
    }

  }

  function handleDeleteUser(){
    //Problematisk å slette collection med all subdocs og subcollections. Har derfor hardcodet hva som må slettes (uten å faktisk slette brukeren)
    
    //WARNINGMODAL...
    setShowDeleteModal(true)
    
    //REDIRECT UT, logg ut
  }


  useEffect(() => {
    const fetchAll = async () => {

      if(confirmDelete===true){

          const docRefSettings = doc(db,"users",`${user.email}`, "settings","privacy")
          setDoc(docRefSettings, {activityCheckbox: false, mailInfoCheckbox: false})
          .then(docRef => {
          })
          .catch(error => {
              console.log(error);
          })
          const formsCollection = collection(db,"users",`${user.email}`, "forms")
          const docRefDashboards =  getDocs(formsCollection).then((docRef2) => {
            docRef2.docs.forEach(async(snapshot) => {
              await deleteDoc(doc(db,"users",`${user.email}`, "forms",snapshot.id))
              handleSignOut()

              
            })
          })
      }
        
    }       
    fetchAll()
  },[confirmDelete])



  return (
    
    //Fieldset er checkbox
    <>
    <div className='bg-gray-50 h-full lg:py-2 lg:border-y-2'>
        <div className='lg:w-1/2 xl:w-1/3 mx-auto pt-5 pb-32 lg:border-4 lg:rounded-lg bg-cyan-700 border-slate-900'>
          <div className='font-bold text-white text-2xl'>Profile & Settings</div>

          <div className='justify-center'>

            <div className='p-5'>
                <div className='p-5 w-2/3 bg-slate-700 rounded-md mx-auto border-2 border-cyan-600'>

                  <div>
                    <span className="inline-block h-32 w-32 overflow-hidden rounded-md bg-gray-100">
                      <svg className="h-full w-full text-gray-300" fill="currentColor" viewBox="0 0 24 24">
                        <path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
                      </svg>
                    </span>
                  </div>
                  {isLoading ?
                    <div className='text-white'>
                    <LoadingSpinner padding={"p-10"} textSize={"text-4xl"} ></LoadingSpinner>
                    </div>
                  :<div>
                    <h2 className="text-l font-bold tracking-tight text-gray-900 sm:text-l">
                    <span className="block text-cyan-500 text-l ">User</span>
                    </h2>
                    <h2 className="font-bold tracking-tight text-gray-900 ">
                        <span className="block text-white text-base 2xl:text-xl">{user?.displayName}</span>
                    </h2>
                    <h2 className="text-l font-bold tracking-tight text-gray-900 sm:text-l">
                        <span className="block text-cyan-500 text-l">Email</span>
                    </h2>
                    <h2 className="font-bold tracking-tight text-gray-900">
                        <span className="block text-white 2xl:text-xl">{user?.email}</span>
                    </h2>
                    </div>

                  }
    
              </div>
            </div>
            <div className='flex justify-center '>
              <fieldset className="space-y-5 w-2/3">
              <legend className="sr-only">Notifications</legend>
              <div className="relative flex items-start bg-slate-700 border-2 border-cyan-600 p-4 rounded-md">
                <div className="flex h-5 items-center">
                  <input

                    id="comments"
                    aria-describedby="comments-description"
                    name="comments"
                    type="checkbox"
                    checked = {activityCheckbox}
                    className="h-4 w-4 rounded border-gray-300 text-cyan-500 focus:ring-cyan-500"
                    onChange={(e) => setActivityCheckbox(e.target.checked)}

                  />
                </div>
                <div className="ml-3 text-sm">
                  <label htmlFor="comments" className="font-medium text-gray-200">
                    Activity
                  </label>
                  <p id="comments-description" className="text-cyan-400 p-2">
                    Allow us to track your usage of this site (#logins, #site visits)
                  </p>
                  {boolCheckboxText(originalActivityState)}

                </div>
              </div>
              <div className="relative flex items-start bg-slate-700 border-2 border-cyan-600 p-4 rounded-md">
                <div className="flex h-5 items-center">
                  <input
                    id="candidates"
                    aria-describedby="candidates-description"
                    name="candidates"
                    checked = {mailInfoCheckbox}

                    type="checkbox"
                    className="h-4 w-4 rounded border-gray-300 text-cyan-400 focus:ring-cyan-500"
                    onChange={(e) => setMailInfoCheckbox(e.target.checked)}

                  />
                </div>
                <div className="ml-3 text-sm">
                  <label htmlFor="candidates" className="font-medium text-gray-200">
                    Information by mail
                  </label>
                  <p id="candidates-description" className="text-cyan-400 p-2">
                    Allow us to keep you notified of changes to the site, bugs, etc. by contacting you by email
                  </p>
                  {boolCheckboxText(originalMailInfoState)}
                </div>
              </div>
              
            </fieldset>
            </div>
            <div className='flex flex-col justify-center w-48 mx-auto'>
                {notification()}
                <DeactivateAccountModal successBool={showDeleteModal} setSuccessBool={setShowDeleteModal} setConfirmDelete={setConfirmDelete}/>
               <button onClick={handleSubmit} className='mt-5 bg-cyan-600 text-white rounded h-10  px-5 py-1'>Submit preferences</button>
               <button onClick={handleDeleteUser} className='mt-5 bg-rose-600 text-white rounded h-10  px-5 py-1'>Delete my user</button>
            </div>
        </div>

      </div>


   </div>
    <Footer></Footer>
    </>

  )
}

export default Account