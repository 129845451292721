import React, { useState } from 'react'

import { WindowIcon, XMarkIcon } from '@heroicons/react/20/solid'
import {db} from "../config/firebase"
import { collection, addDoc, setDoc, doc} from "firebase/firestore"
import { auth } from '../config/firebase';
import { XCircleIcon } from '@heroicons/react/20/solid'

import SuccessModal from './SuccessModal';






function CreateDashboardForm() {
    const [formName, setFormName] = useState("")
    const [about, setAbout] = useState("")
    
    const [tickerLimitAlert, setTickerLimitAlert] = useState(false)
    const [successfulSubmit, setSuccessfulSubmit] = useState("INITSTATE")
    const [updateCount, setUpdateCount] = useState(0)
    
    const [formFields, setFormFields] = useState([
        {address: "",
        //appName: "",
        //companyName: ""
        }
    ])
    const handleFormChange = (e, index) => {
        let data = [...formFields]
        data[index][e.target.name] = e.target.value
        setFormFields(data)
        //console.log(formFields)
    }
    

const addFields = () => {
    //console.log("LENGTH:_",formFields.length)
    if(formFields.length>=30){
        setTickerLimitAlert(true)
    }else{
        setTickerLimitAlert(false)
        let object = {
            address: "",
            //appName: "",
            //companyName: "",
        }
        setFormFields([...formFields, object])
    }  
}

    const removeFields = (index) => {
        let data = [...formFields]
        data.splice(index, 1)
        setFormFields(data)
    }

    const handleSubmit = async(e) => {
        e.preventDefault()
        

        //For å skyve data inn i database
/*         if(Object.keys(formName).length) */
        //console.log("FORMNAME",Object.keys(formName).length)
        if(formName.length === 0 || Object.keys(formFields).length === 0 ){
            setSuccessfulSubmit(false)
            setUpdateCount(updateCount+1)
            return null
        }

        try{
            await setDoc(doc(db, "users",auth.currentUser.email, "forms", formName), {
                formName: formName,
                formFields: formFields
                
            })
            setSuccessfulSubmit(true)


        } catch {
            setSuccessfulSubmit(false)

            //console.log("NOE GIKK GALT")
        }
        //For å skyve inn i database for apper man tracker.
        formFields.map(async(e) => {
            //console.log(e.address)
            try{

                await setDoc(doc(db, "trackedApps",e.address), {
                    address: e.address
                })
                setSuccessfulSubmit(true)

    
            } catch {
                setSuccessfulSubmit(false)

                //console.log("NOE GIKK GALT")
            }
        })

        setUpdateCount(updateCount+1)
        //console.log("SUCCESSFULSUBMIT",successfulSubmit)


    }


    function warningModalApps(){
        const newFieldsCount =  formFields.length
        if(newFieldsCount>=30){
        return (
            <div className="rounded-md bg-red-50 p-4 m-4">
                <div className="flex">
                    <div className="flex-shrink-0">
                        <XCircleIcon className="h-5 w-5 text-red-400" aria-hidden="true" />
                    </div>
                    <div className="ml-3">
                        <h3 className="text-sm font-medium text-red-800">There is currently a limit on apps you can track per form</h3>
                        <div className="mt-2 text-sm text-red-700">
                        <ul role="list" className="list-disc space-y-1 pl-5">
                            <li>Apps per form is currently limited to 30, due to performance issues</li>
                            <li>You can track more apps by making more dashboards</li>
                        </ul>
                        </div>
                    </div>
                </div>
            </div>
        )
        }
    }



    return (

    <>
    <div className='w-full mt-2 mb-4 p-2 border-2 border-cyan-600 h-screen/2'>

      <div className='p-2 mt-2 '>
      <div className='p-1 bg-cyan-900 rounded'>

        <form>
                <div className='mt-4 text-3xl px-4 p-2 bg-cyan-900 rounded-md text-white w-full'>Dashboard form</div>
                <div className='bg-cyan-900 p-2 border-y my-2'>
                    <div className=" sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:pt-5 pb-2">
                            <label htmlFor="first-name" className="block text-sm font-medium text-white sm:mt-px sm:pt-2">
                                Form name
                            </label>
                            <div className="mt-1 sm:col-span-2 sm:mt-0">
                                <input
                                type="text"
                                name="first-name"
                                id="first-name"
                                autoComplete="given-name"
                                placeholder="e.g. 'Streaming providers' "
                                onChange={(e) => setFormName(e.target.value)}

                                className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-cyan-500 focus:ring-cyan-500 sm:text-sm"
                                />
                            </div>
                    </div>

                </div>
                {formFields.map((form, index)=>{
                    return(
                    <div key={index} className="pt-1">
                        <div className='flex w-5/6 lg:w-2/3 justify-center mx-auto bg-cyan-700 border-2 mt-1 rounded'> 
                                <div className='grid-cols-1 w-3/4 mt-2'>
                                    <label htmlFor="" className="p-2 text-m my-auto font-medium text-white">
                                                Link # {index + 1}
                                    </label>
                                        <div className='grid grid-cols-1 xl:grid-cols-3 justify-between '>

{/*                                             <label htmlFor="address" className="p-2 text-m my-auto font-medium text-white">
                                                Link
                                            </label> */}
                                            <div className="relative py-2 rounded-md shadow-sm xl:col-span-3">
                                                <div className=" absolute  left-0 hidden sm:flex items-center pl-3 my-auto ">
                                                    <WindowIcon className="h-8 w-8 text-gray-400 " aria-hidden="true" />
                                                </div>
                                                <input
                                                type="address"
                                                name="address"
                                                id="address"
                                                className="block w-full rounded-md border-gray-300  pl-2 sm:pl-12 focus:border-cyan-500 focus:ring-cyan-500 p-1"
                                                placeholder="e.g. 'com.netflix.mediaclient'"
                                                onChange={e => handleFormChange(e,index)} value={form.name}
                                                />
                                            </div>
                                        </div>

                                    <div className='py-2 my-auto '>
                                        <button className='bg-white rounded-md p-1 w-full' onClick={() => removeFields(index)}><XMarkIcon className='h-6 w-6 text-red-400 m-auto' /></button>
                                    </div>
                                </div>
                        </div>
                    </div>
                    )
                })}

        </form>
                 <div className='flex flex-col justify-center items-center'>
                    {warningModalApps()}
                    <div className='w-1/2 lg:w-1/2  flex flex-col justify-center items-center'>
                       <button onClick={addFields} className="p-2 m-2 bg-gray-800 rounded border-2 border-cyan-600 text-white w-full">Add more fields</button>
                        <button onClick={handleSubmit} className="p-2 m-2 bg-gray-800 rounded border-2 border-cyan-600 text-white w-full">Submit</button>
                        <SuccessModal updateCount={updateCount} successBool = {successfulSubmit}></SuccessModal>
                    </div>
                </div>
                
            </div>
      </div>
      

       
       
       
    </div>
  </>
  )
}

export default CreateDashboardForm