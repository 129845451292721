/*
  This example requires some changes to your config:
  
  ```
  // tailwind.config.js
  module.exports = {
    // ...
    plugins: [
      // ...
      require('@tailwindcss/forms'),
    ],
  }
  ```
*/
import { Fragment } from 'react'
import { Disclosure, Menu, Transition } from '@headlessui/react'
import { MagnifyingGlassIcon } from '@heroicons/react/20/solid'
import { Bars3Icon, BellIcon, XMarkIcon } from '@heroicons/react/24/outline'
import { Link } from 'react-router-dom'
import { auth } from '../config/firebase'
import {useAuthState} from "react-firebase-hooks/auth"
import { UserAuth } from '../context/AuthContext'
import { Logout } from 'heroicons-react'
import { Navigate,useNavigate } from 'react-router-dom'
import { useState, useEffect } from 'react'
import LoadingSpinner from './LoadingSpinner'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCoffee, faUser, faUserAstronaut, faUserCircle, faUserGraduate, faUserNinja, faUserSlash } from '@fortawesome/free-solid-svg-icons'

const navigation = [
  { name: 'Your dashboards', href: '/dashboard', current: true },
  { name: 'Top Apps', href: '/topApps', current: false },
  { name: 'Top Games', href: '/topGames', current: false },
  { name: 'Guide', href: '/guide', current: false },
  { name: 'About', href: '/about', current: false },
  { name: "Future updates", href: '/futureUpdates', current: false },
]
const userNavigation = [
  { name: "My account", href: '/account' },
]

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export const Navbar = () => {
    const {user, logOut} = UserAuth()
    const [isLoading, setIsLoading] = useState(true)

    const [searchVal, setSearchVal] = useState("")
    const navigate = useNavigate()
    const handleSignOut = async () => {
        try{
            await(logOut())
            navigate("/")
        } catch(error){
            console.log(error)
        }
    }

    const delay = ms => new Promise(
      resolve => setTimeout(resolve, ms)
    );


    useEffect(() => {
      const fetchUserData = async () => {
          
        try{
          await delay(1200)
          setIsLoading(false)
          //console.log(isLoading)
        }
        catch(error){
          console.log(error)
        }

        }       
      fetchUserData()
  },[user?.email])


  return (
    <Disclosure as="header" className="bg-slate-700 shadow">
      {({ open }) => (
        <>
          <div className="mx-auto max-w-7xl px-2 sm:px-4 lg:divide-y lg:divide-gray-200 lg:px-8">
            <div className="relative flex h-16 justify-between">
              <div className="relative z-10 flex px-2 lg:px-0">
                <div className="flex flex-shrink-0 items-center text-white lg:text-3xl">
                    <a href='/'>Google Play Tracker  </a>

                </div>
              </div>
              {/**Søkebar */}
              <div className="relative z-0 flex flex-1 items-center justify-center px-2 sm:absolute sm:inset-0">

                <div className="w-full sm:max-w-xs">

                  <label htmlFor="search" className="sr-only">
                    Search 

                  </label>
                  <div className="relative">
                    
                    <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3 ">
                    </div>
{/*                     <a href='/search' className='absolute inset-y-0 left-0 flex items-center pl-3'>
                    <MagnifyingGlassIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                    </a> */}
                    <Link className='absolute inset-y-0 left-0 flex items-center pl-3' to={`/search`} state={{searchVal}}>
                            <MagnifyingGlassIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                    </Link>
                    <input
                      onChange={(e) => setSearchVal(e.target.value)}
                      
                      id="search"
                      name="search"
                      className="block w-full rounded-md border border-gray-300 bg-white py-2 pl-10 pr-3 text-sm placeholder-gray-500 focus:border-cyan-500 focus:text-gray-900 focus:placeholder-gray-400 focus:outline-none focus:ring-1 focus:ring-cyan-500 sm:text-sm"
                      placeholder="Search for apps"
                      type="search"
                    />
                  </div>
                </div>
              </div> 
              {/*Slutt Søkebar */}
              <div className="relative z-10 flex items-center lg:hidden">
                {/* Mobile menu button */}
                <Disclosure.Button className="inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-cyan-500">
                  <span className="sr-only">Open menu</span>
                  {open ? (
                    <XMarkIcon className="block h-6 w-6" aria-hidden="true" />
                  ) : (
                    <Bars3Icon className="block h-6 w-6" aria-hidden="true" />
                  )}
                </Disclosure.Button>
              </div>
              <div className="hidden lg:relative lg:z-10 lg:ml-4 lg:flex lg:items-center">
                  {/*                 <button
                  type="button"
                  className="flex-shrink-0 rounded-full bg-white p-1 text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2"
                >
                  <span className="sr-only">View notifications</span>
                  <BellIcon className="h-6 w-6" aria-hidden="true" />
                </button> */}

                {/* Profile dropdown */}
                {user?.displayName ? 
                  <Menu as="div" className="relative ml-4 flex-shrink-0">
                    <div>
                      <Menu.Button className="flex rounded-full bg-white focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2">
                        <span className="sr-only">Open user menu</span>
                        <div className='bg-white text-black m-2'>
                            <FontAwesomeIcon icon={faUserNinja}  className={"fa-xl"}></FontAwesomeIcon>
                        </div>       
                        </Menu.Button>
                    </div>
                    <Transition
                      as={Fragment}
                      enter="transition ease-out duration-100"
                      enterFrom="transform opacity-0 scale-95"
                      enterTo="transform opacity-100 scale-100"
                      leave="transition ease-in duration-75"
                      leaveFrom="transform opacity-100 scale-100"
                      leaveTo="transform opacity-0 scale-95"
                    >
                      <Menu.Items className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                        {userNavigation.map((item) => (
                          <Menu.Item key={item.name}>
                            {({ active }) => (
                              <a
                                href={item.href}
                                className={classNames(
                                  active ? 'bg-gray-100' : '',
                                  'block py-2 px-4 text-sm text-gray-700'
                                )}
                              >
                                {item.name}
                              </a>
                            )}
                          </Menu.Item>
                        ))}
                      </Menu.Items>
                    </Transition>
                  </Menu>
                :
                  <div></div>}

              </div>
            </div>
            <div className='flex justify-between'>
                <nav className="hidden lg:flex lg:space-x-8 lg:py-2" aria-label="Global">
                {navigation.map((item) => (
                    <a
                    key={item.name}
                    href={item.href}
                    className={classNames(
                        item.current ? 'bg-gray-100 text-gray-900' : 'text-gray-100 hover:bg-gray-50 hover:text-gray-900',
                        'rounded-md py-2 px-3 inline-flex items-center text-sm font-medium'
                    )}
                    aria-current={item.current ? 'page' : undefined}
                    >
                    {item.name}
                    </a>
                ))}
                </nav>
                <div className='hidden lg:flex items-center justify-center' aria-label="Global">
                    {isLoading ?
                      <button className='bg-cyan-500 text-white rounded h-10 w-32 px-5 py-1' onClick={handleSignOut}>
                          <LoadingSpinner padding={"p-0"} textSize={"text-lg"}></LoadingSpinner>
                       </button>
                      : user?.displayName ?
                        <button className='bg-cyan-500 text-white rounded h-10 w-32 px-5 py-1' onClick={handleSignOut}>
                        Log out 
                        </button>
                      : 
                          <a href='/login' className='bg-cyan-500 text-white h-10 w-32 px-5 rounded-md flex items-center justify-center'>Sign in</a>
/*                         <button className='bg-cyan-500 text-white rounded h-10 w-32 px-5 py-1'>
                          <a href="/login" '>
                              Sign in
                          </a>
                        </button>   */
                    }




                </div>
            </div>
          </div>

          <Disclosure.Panel as="nav" className="lg:hidden" aria-label="Global">
            <div className="space-y-1 px-2 pt-2 pb-3">
              {navigation.map((item) => (
                
                <Disclosure.Button
                  key={item.name}
                  as="a"
                  href={item.href}
                  className={classNames(
                    item.current ? 'bg-gray-100 text-gray-900' : 'text-cyan-100 hover:bg-gray-50 hover:text-gray-900',
                    'block rounded-md py-2 px-3 text-base font-medium'
                  )}
                  aria-current={item.current ? 'page' : undefined}
                >
                  {item.name}
                </Disclosure.Button>
              ))}
               <div className='flex flex-col items-center justify-center'>


                    {user?.displayName ? 
                        <Disclosure.Button className="bg-cyan-500 text-white m-2 w-full py-2 rounded" onClick={handleSignOut}>
                                Log out
                        </Disclosure.Button>
                    :
                        <Disclosure.Button className="bg-cyan-500 text-white m-2 w-full py-2 rounded">
                        <a href="/login">
                                Sign in</a>
                        </Disclosure.Button>
                    }
                </div>
            </div>
            <div className="border-t border-gray-200 pt-4 pb-3 mx-auto">
              <div className="flex items-center px-4">
                <div className="flex-shrink-0">
                </div>
                <div className="mx-auto ">
                  <div className="text-base font-medium text-white">{user?.displayName}</div>
                  <div className="text-sm font-medium text-white">{user?.email}</div>
                </div>
              </div>

              {user?.displayName ? 
                <div className="mt-3 space-y-1 px-2">
                {userNavigation.map((item) => (
                  <Disclosure.Button
                    key={item.name}
                    as="a"
                    href={item.href}
                    className="block rounded-md py-2 px-3 text-base font-medium text-cyan-100 hover:bg-gray-50 hover:text-gray-900"
                  >
                    {item.name}
                  </Disclosure.Button>
                ))}
                </div>
              :
              <div></div> }

            </div>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  )
}