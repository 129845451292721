import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFontAwesome } from '@fortawesome/free-solid-svg-icons'
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons'
import { faEllipsisH } from '@fortawesome/free-solid-svg-icons'

function LoadingSpinner({padding, textSize}) {
  return (
    <div className={`flex justify-center ${padding} `}>
        <FontAwesomeIcon
        icon={faEllipsisH}
        className={`${textSize} animate-ping `}></FontAwesomeIcon>
    </div>
  )
}

export default LoadingSpinner