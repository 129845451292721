// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import {getAuth, GoogleAuthProvider} from "firebase/auth"
import {getFirestore} from "firebase/firestore"
import { FacebookAuthProvider } from 'firebase/auth';
import { getAnalytics } from "firebase/analytics";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyCbqW4_t0RMYZ3-84jE4ldsoFsyBV8mdJY",
  authDomain: "playtracker-e92b4.firebaseapp.com",
  projectId: "playtracker-e92b4",
  storageBucket: "playtracker-e92b4.appspot.com",
  messagingSenderId: "28733432406",
  appId: "1:28733432406:web:a1073fabc5873293a2d1f7",
  measurementId: "G-F0SW9N9N7M"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

export const auth = getAuth()
export const provider = new GoogleAuthProvider()
export const provider2 = new FacebookAuthProvider()
export const db = getFirestore(app)

export default app
