import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/20/solid'
import { useState, useEffect } from 'react';






export default function Pagination({pageInt, setSliceFrom, setSliceTo, tickersPerPage}) {


    let [num, setNum] = useState(1)
    let [cur, setCur] = useState(1)
    var pages = []
    if(pageInt==1){
        pages = [
            { page: num },
        ]
    }else if(pageInt==2){
        pages = [
            { page: num },
            { page: num + 1 }
        ]
    }else{
        pages = [
            { page: num },
            { page: num + 1 },
            { page: num + 2 },
        ]
    }

   function next ()
   {
      num < pageInt-2 && setNum(++num)
   }
   function back ()
   {
      num > 1 && setNum(--num)
   }

   useEffect(() => {
        setSliceFrom((cur-1)*tickersPerPage)
        setSliceTo(cur*tickersPerPage)
    }, [cur])

   return (
    <div className='bg-cyan-900 py-1 border-b-2'>
      <div className="flex rounded-lg font-[Poppins] justify-center ">
         <button onClick={back} className="h-12 border-2 border-r-0 border-white
               px-4 rounded-l-lg bg-cyan-900 text-gray-50 hover:bg-cyan-600 hover:text-white">
            <svg className="w-4 h-4 fill-current" viewBox="0 0 20 20"><path d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z" clipRule="evenodd" fillRule="evenodd"></path>
            </svg>
         </button>
         {
            pages.map((pg, i) => (
               <button key={i} onClick={() => setCur(pg.page)} className={`h-12 border-2 border-r-0 border-white bg-cyan-900 text-gray-50 hover:bg-cyan-600 hover:text-white
               w-12 ${cur === pg.page && 'bg-cyan-500 text-white font-bold'}`}>{pg.page}</button>
            ))
         }
         <button onClick={next} className="h-12 border-2  border-white
               px-4 rounded-r-lg bg-cyan-900 text-gray-50 hover:bg-cyan-600 hover:text-white">
            <svg className="w-4 h-4 fill-current" viewBox="0 0 20 20"><path d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clipRule="evenodd" fillRule="evenodd"></path></svg>
         </button>
      </div>
    </div>
   )
}
