import { ArrowDownIcon, ArrowUpIcon } from '@heroicons/react/20/solid'
import { CursorArrowRaysIcon, EnvelopeOpenIcon, UsersIcon, ArrowDownTrayIcon, StarIcon } from '@heroicons/react/24/outline'
import { useState } from 'react'




function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function AppStats({totalDownloads, avgRating}) {
    let td =totalDownloads
    let avgR =avgRating
    try{    
        td = td.toLocaleString('en-US')
        avgR = avgRating.toFixed(2)}
    catch{
        td="N/A"
        avgR ="N/A"
    }

    let stats = [
        { id: 1, name: 'Total Downloads', stat: td, icon: ArrowDownTrayIcon, change: '122', changeType: 'increase' },
        { id: 2, name: 'Average Rating', stat: avgR, icon: StarIcon, change: '5.4%', changeType: 'increase' },
      ]

    
  return (
    <div>
      <dl className="mt-1 grid grid-cols-1 gap-2 sm:grid-cols-1 3xl:grid-cols-2 p-2">
        {stats.map((item) => (
          <div
            key={item.id}
            className="relative overflow-hidden rounded-lg bg-gray-900 px-4 pt-5 shadow sm:px-6 sm:pt-6"
          >
            <dt >
              <div className="absolute rounded-md bg-cyan-600 p-3">
                <item.icon className="h-6 w-6 text-white" aria-hidden="true" />
              </div>
              <p className="ml-16 truncate text-sm font-medium text-gray-400">{item.name}</p>
            </dt>
            <dd className="ml-16 flex items-baseline pb-6 sm:pb-7">
              <div className='m-auto'>
                <p className="md:text-md lg:text-2xl font-semibold text-white">{item.stat}</p>
              </div>
{/*               <p
                className={classNames(
                  item.changeType === 'increase' ? 'text-green-600' : 'text-red-600',
                  'ml-2 flex items-baseline text-sm font-semibold'
                )}
              >
                {item.changeType === 'increase' ? (
                  <ArrowUpIcon className="h-5 w-5 flex-shrink-0 self-center text-green-500" aria-hidden="true" />
                ) : (
                  <ArrowDownIcon className="h-5 w-5 flex-shrink-0 self-center text-red-500" aria-hidden="true" />
                )}

                <span className="sr-only"> {item.changeType === 'increase' ? 'Increased' : 'Decreased'} by </span>
                {item.change}
              </p> */}
{/*               <div className="absolute inset-x-0 bottom-0 bg-gray-800 px-4 py-4 sm:px-6">
                <div className="text-sm">
                  <a href="#" className="font-medium text-white hover:text-gray-300">
                    {' '}
                    View all<span className="sr-only"> {item.name} stats</span>
                  </a>
                </div>
              </div> */}
            </dd>
          </div>
        ))}
      </dl>
    </div>
  )
}
