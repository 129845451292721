import React from 'react'
import Chart from "react-apexcharts"
import { UserAuth } from '../context/AuthContext'
import { db } from './../config/firebase';
import { useEffect, useState } from 'react';
import { getDoc, getDocs,collection, doc } from 'firebase/firestore';
import AppStats from './AppStats';
import { Link } from 'react-router-dom';
import moment from 'moment/moment';
import { format, isAfter } from 'date-fns';
import { ColorSwatch } from 'heroicons-react';
import { isBefore } from 'date-fns/esm';
import StandardLineChart from './StandardLineChart';
import { act } from 'react-dom/test-utils';




//Interpolerer et input-array der verdiene eks. er som følger: [100,0,0,115,125]
function interpolateArray(input) {
    let output = [], zeros = 0, start = 0;
    input.forEach(value => {
        if (value) {
            start = start || value;
            if (zeros) {
                let step = (value - start) / (zeros + 1);
                while (zeros--) output.push(start += step);
                zeros = 0;
            }
            output.push(start = value);
        } else zeros++;
    });
    while (zeros--) output.push(start);
    return output;
}

function returnInterpolatedArrays(xObj, yObj){
    const findDuplicates = (xObj) => xObj.map((item, index) => {
        if (xObj.indexOf(item) !== index)
            return index;
        }).filter(x => x);  // Equivalent to .filter(x => x != undefined || x != null)
        const xDuplicates = findDuplicates(xObj);
        for (let x of xDuplicates){
            xObj[x] = ""
            yObj[x] = ""
        }
        const yDuplicates = findDuplicates(yObj)
        for (let x of yDuplicates){
            yObj[x] = ""
            
        }

        let xyObj = xObj.map((x, i) => ({ x, y: yObj[i] }));
        console.log("XYOBJPRE", xyObj)
        xyObj = xyObj.filter(function (e){
            return (e.x !== "" && e.y !== "")
        })
        console.log("XYOBJ", xyObj)


        const l = xyObj.length
        const positions = [0]
        for(var i=0; i<l-1;i++){
            const startDate = moment(xyObj[0].x)
            const endDate = moment(xyObj[i+1].x)
            const days = endDate.diff(startDate, 'd', false);
            positions.push(days)
        }
        //Posisjonene er der det skal mates inn faktiske tall
        //console.log(positions)

        var startDate = moment(xyObj[0].x)
        var endDate = moment(xyObj[xyObj.length-1].x)
        var days = endDate.diff(startDate, 'd', false)
        var xListNew = [] 
        var yListNew = [] 
        for (var i = 0; i <= days; i++) {
            //xListNew.splice(i,0, {"x" : startDate.add(1, 'd')._d.toISOString(), 'y': ""  })
            xListNew.splice(i,0,  startDate.add(1, 'd')._d.toISOString().substring(0, 10))
            yListNew.splice(i,0, 0 )
        }
        for (var i in positions){
            yListNew[positions[i]] = xyObj[i].y
        }
        yListNew = interpolateArray(yListNew)
        

        //Fikse sånn at vi kan ekstrapolere dersom data ikke oppdateres jevnlig (f.eks. ved en uke uten oppdateringer)

        const similarsAtEnd = yListNew.filter(c => c === yListNew[yListNew.length-1]).length;
        //Første argument sjekker at man har nok historikk, andre argument sjekker at det ikke er en død app
        if(yListNew.length>similarsAtEnd+7 && similarsAtEnd<20){
        const increaseRate = (yListNew[yListNew.length-1-similarsAtEnd]-yListNew[yListNew.length-1-similarsAtEnd-7])/7
        for (var k=1;k<=similarsAtEnd;k++){
            //console.log(yListNew[yListNew.length-1-similarsAtEnd+1] + k*increaseRate)
            yListNew[yListNew.length-1-similarsAtEnd+k] = yListNew[yListNew.length-1-similarsAtEnd+1] + k*increaseRate
        } 
        }
        console.log("XLISTNEW",xListNew)
        console.log("yLISTNEW",yListNew)
        

        return [xListNew, yListNew]
}

function differenceArrayElements(inputArray) {
    //DEEP COPY KLONE
    const array = structuredClone(inputArray);
    if(array.length>1){
        for (var i = array.length-1; i >0 ; i--){
            array[i] = parseInt(array[i]-array[i-1])
        }
        array[0] = array[1]
    }else{
        array[0]=0
    }
    return array

}

function sevenDaysMA(inputArray) {
    //Trenger ikke deep copy. 
        var tempArray =  structuredClone(inputArray) 
        //console.log("TEMPARRAY LENGTH", tempArray)
        for (var i = 0; i <= (tempArray.length-1) ; i++){
            if (i==0 && tempArray.length>i+2){
                tempArray[i] = (inputArray[i]+inputArray[i+1]+inputArray[i+2])/3
            }
            else if (i==0 && tempArray.length>i+1){
                tempArray[i] = (inputArray[i]+inputArray[i+1])/2
            }
            else if(i<6 && inputArray.length>i+1 ){
                tempArray[i] = (inputArray[i]+inputArray[i-1]+inputArray[i+1])/3
            }
            else if(i>=6){
                tempArray[i] = (inputArray[i]+inputArray[i-1]+inputArray[i-2]+inputArray[i-3]+inputArray[i-4]+inputArray[i-5]+inputArray[i-6])/7
            }

        }
        if(tempArray.length==1){
            tempArray= [0]
        }
    return tempArray

}

function plotChart(xInputArray, yInputArray, title,selectedChartType, yMin, yMax) {

    return(
        <>
            <Chart
            type="area"
            //width={"100%"}
            height={"100%"}
            
            
            series={[
                {
                    name: title,
                    //data: [{ x: '05/06/2022', y: 54 },{ x: '05/06/2022', y: 57 },{ x: '05/07/2022', y: 81 },{ x: '05/08/2022', y: 79 },{ x: '05/09/2022', y: 84 },{ x: '05/10/2022', y: 124 },{ x: '05/11/2022', y: 180 },{ x: '05/12/2022', y: 132 },{ x: '05/13/2022', y: 249 },{ x: '05/14/2022', y: 246 },{ x: '05/15/2022', y: 228 },{ x: '05/16/2022', y: 390 }, { x: '05/17/2022', y: 632 } , { x: '05/18/2022', y: 921 },{ x: '05/17/2022', y: 1290 } ,]  
                    data: yInputArray //Installist
                }
            ]}

            options={{
                title:{text: selectedChartType, style:{color:"white"}},
                colors:["#0f172a"],
                fill: {
                    type: 'gradient',
                    gradient: {
                      shadeIntensity: 1,
                      inverseColors: false,
                      opacityFrom: 1,
                      opacityTo: 0.8,
                      stops: [0, 95, 100]
                    }},

                dataLabels: {
                    enabled:false,
                    formatter: function (value) {
                        return value.toLocaleString("en-US")
                    },
                    style: {
                    colors: ["white"],
                    size:8
                    
                    }
                },
                xaxis:{
                    categories: xInputArray, //installDateList
                    labels:{
                        style:{
                            colors:"white"
                        }
                    }
                },
                yaxis:{
                    min: Math.min.apply(1,yInputArray)<0 ? -Math.max.apply(1,yInputArray) :  Math.min.apply(null,yInputArray)*yMin,//min:  -((Math.max.apply(null, yInputArray) <= 5) ? parseFloat(5.00) : Math.max.apply(null, yInputArray)*yMax),//Math.min.apply(null,yInputArray)*yMin,
                    max: (Math.max.apply(null, yInputArray) <= 5) ? parseFloat(5.00) : Math.max.apply(null, yInputArray)*yMax ,//parseInt(yInputArray[0]*yMin),
                    //parseInt(cumulativeInstallList[0]*0.998),
                    //max: parseInt(yInputArray[yInputArray.length-1]*yMax),
                    labels:{
                        formatter: function (value) {
                            if(value<=100){
                                return parseFloat(value).toLocaleString("en-US")
                            }else{
                                return parseInt(value).toLocaleString("en-US")
                            }
                        },
                        style:{
                            colors:"white"

                        }
                    }
                },

            }}
            >

        </Chart>
    </>
    )
}


const options = { labels: ["1 star ", "2 stars", "3 stars", "4 stars", "5 stars"],
title:{text: "", style:{color:"white"}},
colors:["#06b6d4", "#0891b2", "#0e7490", "#075985","#164e63"],
dataLabels: {
    borderRadius:0,
    borderColor: '#18181b',
    foreColor: '#18181b',
    style: {
    fontSize: '12px',

    colors: ["white"],
    padding: 0,

    }},
legend:{
    labels:{
        colors: ["#881337", "#ca8a04", "#facc15", "#a3e635","#111827"]
    }
    },
plotOptions: {
    pie: {
        donut: {
        size: "50%"
        }
    }
    }
}


function SingleChart({tickerRankText, selectedChartType, fromToDates}) {


    //console.log("TICKERRANKTEXT",tickerRankText)
    const tickerText = tickerRankText.address
    //console.log("TICKERTExt", tickerText)
    const tickerRank = tickerRankText.rank
    const [isLoading, setIsLoading] = useState(true)
    const [document, setDocument] = useState()
    const [title, setTitle] = useState("...")
    const [developer, setDeveloper] = useState("...")
    const [totalDownloads, setTotalDownloads] = useState(0)
    const [avgRating, setAvgRating] = useState("")
    const [ratingDist, setRatingDist] = useState([])
    const [installDateList, setInstallDateList] = useState([])
    const [cumulativeInstallList, setCumulativeInstallList] = useState([])
    const [cumulativeRatingsList, setCumulativeRatingsList] = useState([])
    const [cumulativeReviewsList, setCumulativeReviewsList] = useState([])
    const [cumulativeScoreList, setCumulativeScoreList] = useState([])
    const [dailyInstallList, setDailyInstallList] = useState([])
    const [dailyRatingsList, setDailyRatingsList] = useState([])
    const [dailyReviewsList, setDailyReviewsList] = useState([])
    const [dailyScoreList, setDailyScoreList] = useState([])

    //Legge inn argumenter til plotting
    const [activeYList, setActiveYList] = useState([dailyInstallList])
    const [yMin, setYMin] = useState(0.90)
    const [yMax, setYMax] = useState(1.05)


    
    useEffect(() => {
        const fetchAll = async () => {
            const docSnap = await getDoc(doc(db, "trackedAppsDatabase",`${tickerText}`))

            if(docSnap.data()==undefined){
                setTitle(tickerText)
                setDeveloper("data available in 1-3 days")
                setTotalDownloads([])
                setAvgRating([])
                setRatingDist([])
                setInstallDateList(["NaN"])
                setCumulativeInstallList([])
                setCumulativeRatingsList([])
                setCumulativeReviewsList([])
                setCumulativeScoreList([])
                setDailyInstallList([])
                setDailyRatingsList([])
                setDailyReviewsList([])
                setDailyScoreList([])
                return;
            }else{}
            setDocument(docSnap.data())
            
            //setForm(data.docs.map((doc) => ({...doc.data(), id: doc.formName})))
            setIsLoading(false)
            setDeveloper(docSnap.data().developer)
            setTitle(docSnap.data().title)
            setTotalDownloads(docSnap.data().maxInstalls.installs[docSnap.data().maxInstalls.installs.length -1 ])
            setAvgRating(docSnap.data().maxScore.score[docSnap.data().maxScore.score.length-1])
            setRatingDist([docSnap.data().histogram[1], docSnap.data().histogram[2], docSnap.data().histogram[3], docSnap.data().histogram[4], docSnap.data().histogram[5]])
            
            //Finne eventuelle duplikater dersom kode har kjørt flere ganger samme dag og dobbeltelt datoer og installeringer. Må også implementeres for reviews
            const xObj = docSnap.data().maxInstalls.date

            //console.log("XOBJ", xObj)
            const historyAvailableFirstDate = new Date(xObj[0])
            const historyAvailableLastDate = new Date(xObj[xObj.length-1])

            let [historyAvailable, historyAvailable2] = returnInterpolatedArrays(xObj, xObj)
/* 
            console.log("HISTORYAVAILABLE",historyAvailable)
            console.log("yOBJ", docSnap.data().maxInstalls.installs)

            console.log("TITLE", docSnap.data().title)
            console.log("FirstCut",format(fromToDates[0].startDate,"yyyy-MM-dd"))
            console.log("SecondCut",format(fromToDates[0].endDate,"yyyy-MM-dd"))
            console.log("HISTORYFIRST",historyAvailableFirstDate)
            console.log("HISTORYLAST",historyAvailableLastDate) */

/*          var indexCutFirst = xObj.indexOf(format(fromToDates[0].startDate,"M/d/yyyy"))
            var indexCutLast = xObj.indexOf(format(fromToDates[0].endDate,"M/d/yyyy")) */
            var indexCutFirst = -1
            var indexCutLast = -1
            //Hvis både før og etter er før, eller før og etter er etter, sett
            //Handle the last cutoff
            //If last cutoff is after last objectDate, then don't cut off at the end.
            if (isAfter(fromToDates[0].endDate,historyAvailableLastDate)){
                indexCutLast=historyAvailable.length
            //Else if last cutoff is before first objectDate, then cut the whole object   
            }else if(isBefore(fromToDates[0].endDate,historyAvailableFirstDate)){
                indexCutFirst=0
                indexCutLast=0
            //Else The last cutoff is somewhere in between first and last date 
            }else{
                indexCutLast = historyAvailable.indexOf(format(fromToDates[0].endDate,"yyyy-MM-dd"))
            }
            //Handle the first cutoff
            //If the first cutoff is before the first objectdate, then don't cut off at the beginning
            if (isBefore(fromToDates[0].startDate,historyAvailableFirstDate)){
                indexCutFirst=0
            //Else if first cutoff is after last objectDate, then cut the whole object  
            }
            else if (isAfter(fromToDates[0].startDate,historyAvailableLastDate)){
                indexCutFirst=0
                indexCutLast=-0
            //Else the first cutoff is somewhere in between first and last date
            }else{
                indexCutFirst = historyAvailable.indexOf(format(fromToDates[0].startDate,"yyyy-MM-dd"))
            }
        

            var yObjInstalls = docSnap.data().maxInstalls.installs //.slice(indexCutFirst,indexCutLast+1)
            var yObjRatings = docSnap.data().maxRatings.ratings //.slice(indexCutFirst,indexCutLast+1)
            var yObjReviews = docSnap.data().maxReviews.reviews //.slice(indexCutFirst,indexCutLast+1)
            var yObjScore = docSnap.data().maxScore.score //.slice(indexCutFirst,indexCutLast+1)

            console.log(docSnap.data().title)
/*             console.log("yOBJINSTALLS", yObjInstalls)
 */
            let [datesListInterpolated, installsListInterpolated] = returnInterpolatedArrays(xObj, yObjInstalls)
            console.log("datesListInterpolated",datesListInterpolated)
            console.log("installsListInterpolated",installsListInterpolated)
            let [k, ratingsListInterpolated] = returnInterpolatedArrays(xObj, yObjRatings)
            let [l, reviewsListInterpolated] = returnInterpolatedArrays(xObj, yObjReviews)
            let [m, scoreListInterpolated] = returnInterpolatedArrays(xObj, yObjScore)
            if((isAfter(fromToDates[0].startDate,historyAvailableLastDate) && isAfter(fromToDates[0].endDate,historyAvailableLastDate)) || (isBefore(fromToDates[0].startDate,historyAvailableFirstDate) && isBefore(fromToDates[0].endDate,historyAvailableFirstDate))){            
                let [datesListInterpolated, nullListInterpolated] = returnInterpolatedArrays([format(fromToDates[0].startDate,"MM/dd/yyyy"),format(fromToDates[0].endDate,"MM/dd/yyyy")], [0,0])
                setInstallDateList(datesListInterpolated)
                setCumulativeInstallList(nullListInterpolated)
                setCumulativeRatingsList(nullListInterpolated)
                setCumulativeReviewsList(nullListInterpolated)
                setCumulativeScoreList(nullListInterpolated)
                setDailyInstallList(nullListInterpolated)
                setDailyRatingsList(nullListInterpolated)
                setDailyReviewsList(nullListInterpolated)
                setDailyScoreList(nullListInterpolated)
            }else{
                if (datesListInterpolated.length>1){
                    setInstallDateList(datesListInterpolated.slice(indexCutFirst,indexCutLast+1))
                    setCumulativeInstallList(installsListInterpolated.slice(indexCutFirst,indexCutLast+1))
                    setCumulativeRatingsList(ratingsListInterpolated.slice(indexCutFirst,indexCutLast+1))
                    setCumulativeReviewsList(reviewsListInterpolated.slice(indexCutFirst,indexCutLast+1))
                    setCumulativeScoreList(scoreListInterpolated.slice(indexCutFirst,indexCutLast+1))
                }else{
                    setInstallDateList(xObj.slice(indexCutFirst,indexCutLast+1))
                    setCumulativeInstallList(yObjInstalls.slice(indexCutFirst,indexCutLast+1))
                    setCumulativeRatingsList(yObjRatings.slice(indexCutFirst,indexCutLast+1))
                    setCumulativeReviewsList(yObjReviews.slice(indexCutFirst,indexCutLast+1))
                    setCumulativeScoreList(yObjScore.slice(indexCutFirst,indexCutLast+1))
                }
                if(installsListInterpolated.length>1){
                    var differencedArrayInstall = differenceArrayElements(installsListInterpolated)
                    var differencedArrayRatings = differenceArrayElements(ratingsListInterpolated)
                    var differencedArrayReviews = differenceArrayElements(reviewsListInterpolated)
                    var differencedArrayScore = differenceArrayElements(scoreListInterpolated)
                }else{
                    var differencedArrayInstall = installsListInterpolated
                    var differencedArrayRatings = ratingsListInterpolated
                    var differencedArrayReviews = reviewsListInterpolated
                    var differencedArrayScore = scoreListInterpolated
                }
                differencedArrayInstall = sevenDaysMA(differencedArrayInstall)
                differencedArrayRatings = sevenDaysMA(differencedArrayRatings)
                differencedArrayReviews = sevenDaysMA(differencedArrayReviews)
                differencedArrayScore = sevenDaysMA(differencedArrayScore)
    
                setDailyInstallList(differencedArrayInstall.slice(indexCutFirst,indexCutLast+1))
                setDailyRatingsList(differencedArrayRatings.slice(indexCutFirst,indexCutLast+1))
                setDailyReviewsList(differencedArrayReviews.slice(indexCutFirst,indexCutLast+1))
                setDailyScoreList(differencedArrayScore.slice(indexCutFirst,indexCutLast+1))
                //setDailyPctList(differencedArrayScore.slice(indexCutFirst,indexCutLast+1).map(function(n, i) { return n*1000 / cumulativeInstallList[i]; }))
            }
        }    
        fetchAll()
    },[tickerText,fromToDates])

/*     useEffect(() =>{
        var installsPercentList = dailyInstallList.map(function(n, i) { return n*100 / cumulativeInstallList[i]; })
        var ratingsPercentList = dailyRatingsList.map(function(n, i) { return n*100 / cumulativeRatingsList[i]; })
        var reviewsPercentList = dailyReviewsList.map(function(n, i) { return n*100 / cumulativeReviewsList[i]; })
        setDailyPctList(installsPercentList)
    },[dailyInstallList, cumulativeInstallList])
    useEffect(() =>{
        var ratingsPercentList = dailyRatingsList.map(function(n, i) { return n*100 / cumulativeRatingsList[i]; })
        //setDailyPctList(installsPercentList)
    },[dailyRatingsList, cumulativeRatingsList])
    useEffect(() =>{
        var reviewsPercentList = dailyReviewsList.map(function(n, i) { return n*100 / cumulativeReviewsList[i]; })
        //setDailyPctList(installsPercentList)
    },[dailyReviewsList, cumulativeReviewsList]) */

    useEffect(() => {
        if(selectedChartType==="Daily Installs"){
            setActiveYList(dailyInstallList)
            setYMin(0)
        }
        else if(selectedChartType==="Cumulative Installs"){
            setActiveYList(cumulativeInstallList)
            setYMin(0.995)
            setYMax(1.005)
        }
        else if(selectedChartType==="Daily Reviews"){
            setActiveYList(dailyReviewsList)
            setYMin(0)
            setYMax(1.5)
        }
        else if(selectedChartType==="Cumulative Reviews"){
            setActiveYList(cumulativeReviewsList)
            setYMin(0.9995)
            setYMax(1.0005)
        }
        else if(selectedChartType==="Daily Ratings"){
            setActiveYList(dailyRatingsList)
            setYMin(0)
            setYMax(1.5)
        }
        else if(selectedChartType==="Cumulative Ratings"){
            setActiveYList(cumulativeRatingsList)
            setYMin(0.9995)
            setYMax(1.0005)
        }
        else if(selectedChartType==="Avg. Rating"){
            setActiveYList(cumulativeScoreList)
            setYMin(0.0)
            setYMax(1.00)
        }
/*         else if(selectedChartType==="Pct. Growth Rates"){
            setActiveYList(dailyPctList)
            console.log("DAILYPCTLIST", dailyPctList)
            setYMin(0.0)
            setYMax(1.00)
        } */
    }, [selectedChartType, dailyInstallList])



  return (
    <div className='bg-gray-900'>
        <div className='bg-gray-900  lg:w-full xl:w-2/3 mx-auto p-2 rounded-sm'>
            <div className='bg-cyan-900 rounded-lg'>
                <div className="grid grid-cols-3 gap-3 lg:grid-cols-5 border-2">
                    <div className='col-span-5 lg:col-span-2 text-white text-xl font-black'>
                        <div className='mt-3 px-2'>
                             {title} ({developer})
                        </div>
                        <div>
                            <AppStats totalDownloads={totalDownloads} avgRating={avgRating}></AppStats>
                        </div>
                        <div className='justify-center rounded-md bg-gray-900 m-2'>
                            <div>Rating distribution</div>
                            <div className="donut flex justify-center">
                                <Chart options={options} series={ratingDist} type="donut" width="300" />
                            </div>
                        </div>
                        <div className='bg-cyan-700 rounded-md m-2 border-4 border-black hover:bg-cyan-500'>
                        <Link className=' block' to={`/appinfo/${tickerText}`} state={{ document }}>
                                View more
                        </Link>
                            {/* Kan ikke lagre over info på denne måten (state) med target=_blank (åpner ny fane) */}
{/*                             <Link to={`/appinfo/${tickerText}`}  target="_blank" rel="noopener noreferrer">
                            <button >View More</button>
                            </Link> */}
                        </div>

                    </div>
                    <div className='col-span-3 ml-3 py-5 max-h-full h-96 lg:h-full'>

                    
                       
                        {plotChart(installDateList, activeYList,title,selectedChartType, yMin, yMax)} 
{/*                         <StandardLineChart xList={installDateList} yList={activeYList} yMin={yMin} yMax={yMax} />
 */}


                       
                    </div>
                </div>
            </div>

        </div>
    </div>
  )
}

export default SingleChart