import React from 'react'
import robloxImage from "../assets/images/2022-6-11_Roblox_Google_Play.png"
import Account from './Account';
import Footer from './Footer';
function Guide() {
  return (
    <div>
        <div className="bg-cyan-700 border-y-2 ">
          <div className="mx-auto max-w-7xl py-12 px-4 text-center sm:px-6 lg:py-16 lg:px-8">
              <h2 className="text-lg font-bold tracking-tight text-gray-900 sm:text-2xl">
                <span className="block text-white">You can freely, without registering, take a look at the "Top Apps" and "Top Games" tabs</span>
                <span className="block text-white">This guide, however, leads you through the simple steps required to make your own dashboards</span>
              </h2>
              <h6 className='text-sm md:text-lg font-semibold tracking-tighter'>
                <span className="block text-white text-lg font-bold">In summary, the steps required are:</span>
                <span className="block text-slate-200">1) Authenticate yourself via your Google/Gmail-user</span>
                <span className="block text-slate-200">2) Go to "Your dashboard" and click the "Create a new dashboard"-button </span>
                <span className="block text-slate-200">3) Fill in the form. The steps needed to fill in the form is shown below</span>
              </h6>
          </div>
        </div>
        <div className="bg-slate-800 py-2 border-b-2">
          <div className="mx-auto max-w-7xl px-4 text-center sm:px-6 lg:py-16 lg:px-8 ">
            <div className="grid grid-cols-3 lg:grid-cols-3 border-2 rounded-lg p-10 border-white my-2 bg-cyan-700">
              <div className='col-span-3 md:col-span-1 mx-3 my-auto'>
                <h1 className='text-3xl sm:text-4xl font-bold tracking-tight text-white'>1. Give your form a name</h1>
              </div>
              <div className='col-span-3 md:col-span-2 text-md md:text-lg py-2 text-white font-semibold'>
                A form name is required. You can name the form as pleased, 
                as long as it only contains alphanumeric characters. The name is for your own sake 
                - to make it easily understandable what your form will be about
              </div>
            </div>
            <div className=' border-2 rounded-lg p-10 border-white-600 my-2 bg-cyan-700'>
              <div className="grid grid-cols-3 lg:grid-cols-3 mb-4 ">
                <div className='col-span-3 md:col-span-1 mx-3 my-auto'>
                  <h1 className='text-3xl sm:text-4xl font-bold tracking-tight text-white'>2. Retrieve the "subdomain" of the Google Play address</h1>
                </div>
                <div className='col-span-3 md:col-span-2 text-md md:text-lg py-2 text-white font-semibold'>
                  <h1 className='m-2'>
                  The "subdomain" is part of the web address of the game or app you want to track.
                  The subdomain is preceeded by "[...]?id=" and followed by "&[...]".
                  Usually the subdomain is starting with "com." See the picture below for reference,
                  where the subdomain is marked.
                  </h1>

                </div>

              </div>
              <div className='grid grid-cols-3 md:flex lg:grid-cols-3 bg-slate-700 rounded justify-center my-2 border-2 border-white-600'>
                <h1 className='col-span-3 lg:col-span-1 text-red-400 whitespace-nowrap text-ellipsis overflow-hidden mx-1'>https://play.google.com/store/apps/details?id=</h1>
                <h1 className='col-span-3 lg:col-span-1 text-green-500 mx-1'>com.roblox.client</h1>
                <h1 className='col-span-3 lg:col-span-1 text-red-400 mx-1'>&hl=en&gl=US</h1>

              </div>
              <img src={robloxImage} className="border-2 border-white-600" />
            </div>

            <div className="grid grid-cols-3 lg:grid-cols-3 border-2 rounded-lg p-10 border-white-600 my-2 bg-cyan-700">
              <div className='col-span-3 md:col-span-1 mx-3 my-auto'>
                <h1 className='text-3xl sm:text-4xl font-bold tracking-tight text-white'>3. Add more apps to your form</h1>
              </div>
              <div className='col-span-3 md:col-span-2 text-md md:text-lg py-2 text-white font-semibold'>
                You can add multiple apps in one form - so that you can track multiple apps in one dashboard.
                Due to data storage costs we have limited the number of apps per form to 30
              </div>
            </div>
          </div>
        </div>

        <Footer></Footer>
        

        
    </div>
  )
}

export default Guide