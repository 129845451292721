import { useState } from 'react'
import { Switch } from '@headlessui/react'

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function AppViewMoreDescription(textProp) {
  const [enabled, setEnabled] = useState(false)


  return (
    <>
    <div className=' justify-center pb-2 px-2'>
        <Switch.Group as="div" className="flex items-center p-4">
        <Switch
            checked={enabled}
            onChange={setEnabled}
            className={classNames(
            enabled ? 'bg-cyan-200' : 'bg-gray-200',
            'relative inline-flex h-4 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-cyan-600 focus:ring-offset-2'
            )}
        >
            <span
            aria-hidden="true"
            className={classNames(
                enabled ? 'translate-x-5' : 'translate-x-0',
                'pointer-events-none inline-block h-3 w-5 transform rounded-full bg-white font-bold shadow ring-0 transition duration-200 ease-in-out'
            )}
            />
        </Switch>
        <Switch.Label as="span" className="ml-3">
            <span className="text-sm font-medium text-cyan-400">{enabled ? <div>Hide full description</div> : <div>Show full description</div>}</span>
        </Switch.Label>
        </Switch.Group>

                {enabled ? 
                    <div>
                        <div className='bg-cyan-600 rounded-lg m-2 p-2'>
                            <div className='text-white font-semibold text-left'>
                            { textProp.textProp}
                            </div>
                        </div>


                    </div>
                     : ""}

    </div>
    </>
  )
}
