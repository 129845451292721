import React from 'react'
import { useLocation } from 'react-router-dom'
import AppStats from './AppStats'
import AppViewMoreDescription from './AppViewMoreDescription'
import {ArrowUpIcon,ArrowDownIcon, CursorArrowRaysIcon, EnvelopeOpenIcon, UsersIcon, ArrowDownTrayIcon, StarIcon } from '@heroicons/react/24/outline'
import StandardLineChart from './StandardLineChart'
import moment from 'moment/moment'
import { useState } from 'react';
import { useEffect } from 'react';

//Interpolerer et input-array der verdiene eks. er som følger: [100,0,0,115,125]
function interpolateArray(input) {
  let output = [], zeros = 0, start = 0;
  input.forEach(value => {
      if (value) {
          start = start || value;
          if (zeros) {
              let step = (value - start) / (zeros + 1);
              while (zeros--) output.push(start += step);
              zeros = 0;
          }
          output.push(start = value);
      } else zeros++;
  });
  while (zeros--) output.push(start);
  return output;
}

function returnInterpolatedArrays(xObj, yObj){
  const findDuplicates = (xObj) => xObj.map((item, index) => {
    if (xObj.indexOf(item) !== index)
        return index;
    }).filter(x => x);  // Equivalent to .filter(x => x != undefined || x != null)
    const xDuplicates = findDuplicates(xObj);
    for (let x of xDuplicates){
        xObj[x] = ""
        yObj[x] = ""
    }
    const yDuplicates = findDuplicates(yObj)
    for (let x of yDuplicates){
        yObj[x] = ""
        
    }


    xObj = xObj.filter(n => n)
    xObj=xObj.map(n=>moment(n,"MM/DD/YYYY")._d.toISOString())
    yObj = yObj.filter(n => n)
    //
    const xyObj = xObj.map((x, i) => ({ x, y: yObj[i] }));

    const l = xyObj.length
    const positions = [0]
    for(var i=0; i<l-1;i++){
        const startDate = moment(xyObj[0].x)
        const endDate = moment(xyObj[i+1].x)
        const days = endDate.diff(startDate, 'd', false);
        positions.push(days)
    }
    //Posisjonene er der det skal mates inn faktiske tall
    //console.log(positions)

    var startDate = moment(xyObj[0].x)
    var endDate = moment(xyObj[xyObj.length-1].x)
    var days = endDate.diff(startDate, 'd', false)
    var xListNew = [] 
    var yListNew = [] 
    for (var i = 0; i <= days; i++) {
        //xListNew.splice(i,0, {"x" : startDate.add(1, 'd')._d.toISOString(), 'y': ""  })
        xListNew.splice(i,0,  startDate.add(1, 'd')._d.toISOString().substring(0, 10))
        yListNew.splice(i,0, 0 )
    }
    for (var i in positions){
        yListNew[positions[i]] = yObj[i]
    }
    yListNew = interpolateArray(yListNew)
    

    //Fikse sånn at vi kan ekstrapolere dersom data ikke oppdateres jevnlig (f.eks. ved en uke uten oppdateringer)

    const similarsAtEnd = yListNew.filter(c => c === yListNew[yListNew.length-1]).length;
    //Første argument sjekker at man har nok historikk, andre argument sjekker at det ikke er en død app
    if(yListNew.length>similarsAtEnd+7 && similarsAtEnd<20){
    const increaseRate = (yListNew[yListNew.length-1-similarsAtEnd]-yListNew[yListNew.length-1-similarsAtEnd-7])/7
    for (var k=1;k<=similarsAtEnd;k++){
        //console.log(yListNew[yListNew.length-1-similarsAtEnd+1] + k*increaseRate)
        yListNew[yListNew.length-1-similarsAtEnd+k] = yListNew[yListNew.length-1-similarsAtEnd+1] + k*increaseRate
    } 
    }

    return [xListNew, yListNew]
}

function differenceArrayElements(inputArray) {
  //DEEP COPY KLONE
  const array = structuredClone(inputArray);
  if(array.length>1){
      for (var i = array.length-1; i >0 ; i--){
          array[i] = parseInt(array[i]-array[i-1])

      }
      array[0] = array[1]
  }else{
      array[0]=0
  }
  //console.log("ARRAYFINISHED", array)
  return array

}

function sevenDaysMA(inputArray) {
  //Trenger ikke deep copy. 
    //Trenger ikke deep copy. 
    var tempArray =  structuredClone(inputArray) 
    for (var i = 0; i <= (tempArray.length-1) ; i++){
        if (i==0 && tempArray.length>i+2){
            tempArray[i] = (inputArray[i]+inputArray[i+1]+inputArray[i+2])/3
        }
        else if (i==0 && tempArray.length>i+1){
            tempArray[i] = (inputArray[i]+inputArray[i+1])/2
        }
        else if(i<6 && inputArray.length>i+1 ){
            tempArray[i] = (inputArray[i]+inputArray[i-1]+inputArray[i+1])/3
        }
        else if(i>=6){
            tempArray[i] = (inputArray[i]+inputArray[i-1]+inputArray[i-2]+inputArray[i-3]+inputArray[i-4]+inputArray[i-5]+inputArray[i-6])/7
        }

    }
return tempArray

}



function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

function AppViewMore(props) {
  const location = useLocation()
  const document  = location.state.document

  const [weeklyDownloads, setWeeklyDownloads] = useState("NaN")
  const [weeklyRatings, setWeeklyRatings] = useState("NaN")
  const [weeklyReviews, setWeeklyReviews] = useState("NaN")
  const [weeklyAverageRating, setWeeklyAverageRating] = useState("NaN")
  const [weeklyDownloadsPct, setWeeklyDownloadsPct] = useState("NaN")
  const [weeklyRatingsPct, setWeeklyRatingsPct] = useState("NaN")
  const [weeklyReviewsPct, setWeeklyReviewsPct] = useState("NaN")
  const [weeklyAverageRatingPct, setWeeklyAverageRatingPct] = useState("NaN")
  const [weeklyDownloadsIncDec, setWeeklyDownloadsIncDec] = useState("increase")
  const [weeklyRatingsIncDec, setWeeklyRatingsIncDec] = useState("increase")
  const [weeklyReviewsIncDec, setWeeklyReviewsIncDec] = useState("increase")
  const [weeklyAverageRatingIncDec, setWeeklyAverageRatingIncDec] = useState("increase")
  //const [dailyDownloads, setDailyDownloads] = useState("NaN")
  //var pathName = window.location.pathname 
  //const tickerText = pathName.split("/appinfo/").pop()
  //Lage en if der if (tickerText!= document.ticker så må det lastes inn data)
  //console.log("document:", document)

/*   var utcSeconds = document.createdAt
  var d = new Date(0)
  d.setUTCSeconds(utcSeconds.seconds+utcSeconds.nanoseconds/(1000000000)) */

  var totalDownloads = document.maxInstalls.installs[document.maxInstalls.installs.length-1].toLocaleString('en-US')
  var totalReviews = document.maxReviews.reviews[document.maxReviews.reviews.length-1].toLocaleString('en-US')
  var totalRatings = document.maxRatings.ratings[document.maxRatings.ratings.length-1].toLocaleString('en-US')
  var averageRating = document.maxScore.score[document.maxScore.score.length-1].toFixed(2)

 
  var datesList = interpolateArray(document.maxInstalls.date)
  var installsList = interpolateArray(document.maxInstalls.installs)
  var ratingsList = interpolateArray(document.maxRatings.ratings)
  var reviewsList = interpolateArray(document.maxReviews.reviews)
  var averageRatingsList = interpolateArray(document.maxScore.score)
  var [datesListInterpolated, installsListInterpolated] = returnInterpolatedArrays(datesList, installsList)
  var [x, ratingsListInterpolated] = returnInterpolatedArrays(datesList, ratingsList)
  var [x, reviewsListInterpolated] = returnInterpolatedArrays(datesList, reviewsList)
  var [x, averageRatingsListInterpolated] = returnInterpolatedArrays(datesList, averageRatingsList)
  var installsListInterpolatedDaily = sevenDaysMA(differenceArrayElements(installsListInterpolated))
  const installsGrowthPercentage = installsListInterpolatedDaily.map(function(n, i) { return n*365*100 /installsListInterpolated[i]; }) 
  const installsPerRating =  installsListInterpolated.map(function(n, i) { return n /ratingsListInterpolated[i]; }) //.map(function(x) { return x * 100; })
  const installsPerReview = installsListInterpolated.map(function(n, i) { return n /reviewsListInterpolated[i]; }) //.map(function(x) { return x * 100; })


  useEffect(() => {
    if(installsListInterpolated.length>0){
        setWeeklyDownloads((installsListInterpolated[installsListInterpolated.length-1]-installsListInterpolated[installsListInterpolated.length-8]))// .toLocaleString('en-US'))
        setWeeklyRatings((ratingsListInterpolated[ratingsListInterpolated.length-1]-ratingsListInterpolated[ratingsListInterpolated.length-8]).toFixed(0))//.toLocaleString("en-US"))
        setWeeklyReviews((reviewsListInterpolated[reviewsListInterpolated.length-1]-reviewsListInterpolated[reviewsListInterpolated.length-8]).toFixed(0))//.toLocaleString("en-US"))
        setWeeklyAverageRating((averageRatingsListInterpolated[averageRatingsListInterpolated.length-1]-averageRatingsListInterpolated[averageRatingsListInterpolated.length-8]).toFixed(2))
        
        setWeeklyDownloadsPct(((installsListInterpolated[installsListInterpolated.length-1]-installsListInterpolated[installsListInterpolated.length-8])/installsListInterpolated[installsListInterpolated.length-8]*100).toFixed(2))
        setWeeklyRatingsPct(((ratingsListInterpolated[ratingsListInterpolated.length-1]-ratingsListInterpolated[ratingsListInterpolated.length-8])/ratingsListInterpolated[ratingsListInterpolated.length-8]*100).toFixed(2))
        setWeeklyReviewsPct(((reviewsListInterpolated[reviewsListInterpolated.length-1]-reviewsListInterpolated[reviewsListInterpolated.length-8])/reviewsListInterpolated[reviewsListInterpolated.length-8]*100).toFixed(2))
        setWeeklyAverageRatingPct(((averageRatingsListInterpolated[averageRatingsListInterpolated.length-1]-averageRatingsListInterpolated[averageRatingsListInterpolated.length-8])/averageRatingsListInterpolated[averageRatingsListInterpolated.length-8]*100))
        //console.log("WEEKLY:", weeklyAverageRatingPct*10000)
        if(weeklyDownloads<0){
          setWeeklyDownloadsIncDec("decrease")
        }
        if(weeklyRatings<0){
          setWeeklyRatingsIncDec("decrease")
        }
        if(weeklyReviews<0){
          setWeeklyReviewsIncDec("decrease")
        }
        if(weeklyAverageRatingPct*100000000<0){
          
          setWeeklyAverageRatingIncDec("decrease")
        }
        //setDailyDownloads(installsListInterpolatedDaily[installsListInterpolatedDaily.length-1]-installsListInterpolatedDaily[installsListInterpolatedDaily.length-8])
    }
  }, [installsListInterpolated])








  let stats = [
    { id: 1, name: 'Total Downloads', stat: totalDownloads, icon: ArrowDownTrayIcon, change: parseInt(weeklyDownloads).toLocaleString("en-US") + " ("+ weeklyDownloadsPct +"%)" , changeType: weeklyDownloadsIncDec },
    { id: 2, name: 'Total Ratings', stat: totalRatings, icon: StarIcon, change: parseInt(weeklyRatings).toLocaleString("en-US")  + " ("+ weeklyRatingsPct +"%)", changeType: weeklyRatingsIncDec },
    { id: 3, name: 'Total Reviews', stat: totalReviews, icon: StarIcon, change: parseInt(weeklyReviews).toLocaleString("en-US")   + " ("+ weeklyReviewsPct +"%)", changeType: weeklyReviewsIncDec },
    { id: 4, name: 'Average Rating', stat: averageRating, icon: StarIcon, change: weeklyAverageRating  + " ("+ parseFloat(weeklyAverageRatingPct).toFixed(3)  +"%)", changeType: weeklyAverageRatingIncDec },
  ]

  
  
  return (
    
      <div className='bg-gray-900'>
        <div className='bg-gray-900  lg:w-full xl:w-2/3 mx-auto p-2 rounded-sm'>
          <div className='bg-cyan-900 '>
            <div className='flex justify-center p-2 '>
              <div className='p-4 my-auto'>
                 <div className='col-span-2 lg:col-span-2 text-white font-black text-4xl md:text-6xl'>{document.title}</div>
                 <div className='col-span-2 lg:col-span-2 text-white font-black text-xl md:text-2xl'>{document.developer}</div>
              </div>
              <img src={document.icon} className="w-24 md:w-32 rounded-lg"></img>
            </div>
            <div className='text-white text-lg font-bold'>{document.summary.replace("amp;", "")}</div>
            <AppViewMoreDescription textProp={document.descriptionHTML.replaceAll("<br>", " ").replaceAll("•","").replaceAll("&#39;s","")}></AppViewMoreDescription>

            <div className='grid grid-cols-2 lg:grid-cols-2 p-2'>

                  {stats.map((item) => (
                    <div
                      key={item.id}
                      className="relative overflow-hidden rounded-lg bg-gray-900 px-4 pt-5 pb-5 shadow sm:px-6 sm:pt-6 m-2"
                    >
                    <div className='grid grid-cols-3 lg:grid-cols-5 '>
                      <div className='col-span-1 lg:col-span-1 mt-2 hidden sm:flex'>
                        <div className='absolute rounded-md bg-cyan-600 p-3 '>
                          <item.icon className=" h-6 w-6 text-white" aria-hidden="true" />
                        </div>

                      </div>
                      <div className='col-span-3 sm:col-span-2 lg:col-span-2 mt-2 mx-auto md:mx-0 '>
                        <p className="truncate text-sm flex justify-end mr-4 ml-4 lg:justify-center font-medium text-cyan-400">{item.name}</p>
                        <p className="truncate text-sm lg:text-xl flex justify-end mr-4 ml-4 lg:justify-center font-semibold text-white mt-1">{item.stat}</p>
                      </div>

                      <div className='col-span-3 lg:col-span-2 mt-2  mx-auto md:mx-0'>
                        <p className="truncate text-sm flex justify-end mr-4 lg:justify-center font-medium text-cyan-400">Weekly change</p>
                          <p
                            className={classNames(
                              item.changeType === 'increase' ? 'text-green-600' : 'text-red-600',
                              'text-sm lg:text-xl flex justify-end mr-4 lg:justify-center  align-middle  items-baseline font-semibold mt-1'
                            )}
                          >
                            {item.changeType === 'increase' ? (
                              <ArrowUpIcon className="h-4 w-4 lg:h-6 lg:w-6 flex-shrink-0 self-center text-green-500" aria-hidden="true" />
                            ) : (
                              <ArrowDownIcon className="h-4 w-4 lg:h-6 lg:w-6 flex-shrink-0 self-center text-red-500" aria-hidden="true" />
                            )}

                            <span className="sr-only text-white"> {item.changeType === 'increase' ? 'Increased' : 'Decreased'} by </span>
                            {item.change}
                          </p>
                      </div>
                    </div>


                    </div>
                  ))}
          </div>

          <div>
            <div className='p-4 my-auto'>
                <div className='col-span-2 lg:col-span-2 text-white font-black text-xl md:text-2xl border-y-2 py-4'>Installs data</div>
            </div>
            <StandardLineChart xList = {datesListInterpolated} yList = {installsListInterpolated} title={"Cumulative installs"} yMin={0.999} yMax={1.001  }></StandardLineChart>
            <StandardLineChart xList = {datesListInterpolated} yList = {installsListInterpolatedDaily} title={"Daily Installs"} yMin={0} yMax={1.1}></StandardLineChart>
            <StandardLineChart xList = {datesListInterpolated} yList = {installsGrowthPercentage} title={["Ann. growth in","cumulative installs [%]"]} yMin={0} yMax={1}></StandardLineChart>
            <div>
                <div className='p-4 my-auto'>
                    <div className='col-span-2 lg:col-span-2 text-white font-black text-xl md:text-2xl border-y-2 py-4'>Inverse Engagement Indicators</div>
                </div>
               <StandardLineChart xList = {datesListInterpolated} yList = {installsPerRating} title={"Installs per rating"} yMin={0.999} yMax={1.001}></StandardLineChart>
               <StandardLineChart xList = {datesListInterpolated} yList = {installsPerReview} title={"Installs per review"} yMin={0.999} yMax={1.001}></StandardLineChart>
            </div>
          </div>  
          </div>
        </div>
      </div>
    
  )
}

export default AppViewMore