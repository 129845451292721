import { useState } from 'react'
import { RadioGroup } from '@headlessui/react'
import { ChevronDoubleDown } from 'heroicons-react'
import { ChevronDoubleUp } from 'heroicons-react'
import FromToBase from './fromTo/FromToBase'

const tickerList = ["com.netflix.mediaclient", "com.disney.disneyplus"]





const memoryOptions = [
  { name: 'Daily Installs', inStock: true },
  { name: 'Daily Ratings', inStock: true },
  { name: 'Daily Reviews', inStock: true },
  { name: 'Cumulative Installs', inStock: true },
  { name: 'Cumulative Ratings', inStock: true },
  { name: 'Cumulative Reviews', inStock: true },
  { name: 'Avg. Rating', inStock: true },


]

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

function ChartTypeSelection(props) {
    //lage usestate for installs, reviews, stjerner, etc.
    const [mem, setMem] = useState(memoryOptions[0])
    props.props.setSelectedChartType(mem.name)  

    const [showDateSelection, setShowDateSelection] = useState(false)


    return (
      <>     
       <div className='relative bg-cyan-700 border-t-2 border-b-2'>
        <div className=' px-10 pt-2 pb-2 '>

                <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
                <span className="block text-white">App statistics</span>
                <span className="block text-slate-800 uppercase">{props.props.selectedDashboard}</span>
                </h2>
            <RadioGroup value={mem} onChange={setMem} className="mt-2 my-2">
              <RadioGroup.Label className="sr-only"> Choose a memory option </RadioGroup.Label>
                <div className="grid grid-cols-3 gap-2 sm:grid-cols-3 justify-end">
                    {memoryOptions.map((option) => (
                    <RadioGroup.Option
                        key={option.name}
                        value={option}
                        className={({ active, checked }) =>
                        classNames(

                            option.inStock ? 'cursor-pointer focus:outline-none' : 'opacity-25 cursor-not-allowed',
                            active ? 'ring-2 ring-offset-2 ring-slate-500' : '',
                            checked
                            ? 'bg-slate-700 border-transparent text-white hover:bg-slate-700'
                            : 'bg-white border-gray-200 text-gray-900 hover:bg-gray-50',
                            'border rounded-md py-1 px-3 flex items-center justify-center text-sm font-medium uppercase sm:flex-1'
                        )
                        }
                        disabled={!option.inStock}
                    >
                        <RadioGroup.Label as="span" >
                          {option.name}</RadioGroup.Label>
                    </RadioGroup.Option>
                    ))}


                    
                  
                </div>
            </RadioGroup>
            <div className="md:absolute md:bottom-0 md:right-0 mb-2  bg-cyan-700 md:mr-10">
                <button onClick={() => setShowDateSelection(open => !open)} className="bg-slate-700 rounded-md w-full ">
                  <div className='flex mx-auto '>
                        <div className='flex bg-slate-700 mx-auto rounded-md'>
                          {showDateSelection ? <div className="pl-4 items-center my-auto">
                              <ChevronDoubleUp className="h-8 w-8 text-white" aria-hidden="true" />
                          </div> : <div className="pl-4 items-center my-auto">
                              <ChevronDoubleDown className="h-8 w-8 text-white" aria-hidden="true" />
                          </div> }
                          <div className='text-white my-auto font-bold pr-4'>
                            Customize date range
                          </div>
                        </div>
                  </div>
   {/*                      <input
                        readOnly
                        value = { 
                        "Customize date range"}
                        className="block rounded-md text-white bg-gray-700  outline-none pl-10  p-1 font-bold"
                        /> */}
                  </button>
             </div>
          </div>
      </div>
      <div className='bg-cyan-700'>
        {showDateSelection ?  <div><FromToBase setFromToDates={props.props.setFromToDates}/></div> : <div></div>}
      </div>
      </>

    )
}

export default ChartTypeSelection