import React from 'react'
import Pagination from './Pagination'
import SingleChart from './SingleChart'
import { useState, useEffect } from 'react'
import StackedChart from './StackedChart'
import SelectChartDisplay from './SelectChartDisplay';
function Charts({tickerListToPlot, selectedChartType, stackedUnstacked, fromToDates}) {
    const tickersPerPage = 10
    const pageInt = Math.ceil(tickerListToPlot.length/tickersPerPage)
/*     console.log("Tickers in total",tickerListToPlot.length)
    console.log("pageInt",pageInt)
    console.log("STACKEDUNSTACKED:", stackedUnstacked)*/
    //console.log("TICKERLISTTOPLOT",tickerListToPlot) 
    const [sliceFrom, setSliceFrom] = useState(0)
    const [sliceTo, setSliceTo] = useState(tickersPerPage)
    const [slicedTickerListToPlot, setSlicedTickerListToPlot] = useState([])


    useEffect(() => {
        setSlicedTickerListToPlot(tickerListToPlot.slice(sliceFrom,sliceTo))
    }, [sliceFrom,sliceTo, tickerListToPlot])

    return (
        <> 
        {tickerListToPlot.length==0 ? 
                <SelectChartDisplay></SelectChartDisplay>
        :
             <>
                {stackedUnstacked=="Unstacked" && <>
                {slicedTickerListToPlot.map((tickerRankText) => {
                    return(<SingleChart tickerRankText={tickerRankText} selectedChartType={selectedChartType} fromToDates={fromToDates}></SingleChart>
                    )
                    
                })}
                <Pagination pageInt={pageInt} setSliceFrom={setSliceFrom} setSliceTo={setSliceTo} tickersPerPage={tickersPerPage}></Pagination>
                </>}
                {stackedUnstacked=="Stacked" && <>
                <StackedChart slicedTickerListToPlot={slicedTickerListToPlot} selectedChartType={selectedChartType} fromToDates={fromToDates} ></StackedChart>
                </>}
            </>
        }
        </>
    )
}

export default Charts