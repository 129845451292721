import React from 'react'
import { useLocation } from 'react-router-dom'
import { useEffect } from 'react'
import { Link } from 'react-router-dom'
import { MagnifyingGlassIcon } from '@heroicons/react/20/solid'
import { useState } from 'react'
import { db } from '../config/firebase'
import { getDocs, getDoc, doc } from 'firebase/firestore';
import { collection } from 'firebase/firestore';
import LoadingSpinner from './LoadingSpinner'
function SearchSite({state}) {

  const location = useLocation()
  const initSearchVal  = location.state.searchVal.toUpperCase()
  const [searchVal, setSearchVal] = useState(initSearchVal)
  const [appData, setAppData] = useState([])
  const [filteredAppData, setFilteredAppData] = useState([])
  const [isLoading, setIsLoading] = useState(true)

      useEffect(() => {
        //FETCHE ALLE I USEEFFECT; så bruke søkealgo på det.
        const fetchAll = async () => {
            //Finne alle dashboard en bruker (den autentiserte) har
            //const citiesRef = db.collection("trackedAppsDat")
/*             const searchJSON = []
 */
            const docRef = doc(db, "trackedAppsConsolidated", "consolidated");
            const snapshot2 = await getDoc(docRef)
/*             searchJSON.push(snapshot2.consolidatedList)
 *//*             const snapshot = await getDocs(collection(db, "trackedAppsDatabase"))
            snapshot.forEach(doc => {
              searchJSON.push(doc.data())
            }) */
            //Lage en "formsList" med alle disse dashboardene.
            setAppData(snapshot2.data().consolidatedList)
            setIsLoading(false)
            }
        fetchAll()
      },[])


      useEffect(() => {
        //FETCHE ALLE I USEEFFECT; så bruke søkealgo på det.
        const fetchAll = async () => {
            //Finne alle dashboard en bruker (den autentiserte) har
            //const citiesRef = db.collection("trackedAppsDat")
            setFilteredAppData(appData.filter(res => res.title.toLowerCase().includes(searchVal.toLowerCase()) || res.developer.toLowerCase().includes(searchVal.toLowerCase()) || res.appId.toLowerCase().includes(searchVal.toLowerCase()) ) )
            
            }
        fetchAll()
      },[searchVal,appData])




    return (
      <>
      <div className='bg-cyan-700 border-t-2 py-12'>
            <h2 className="text-xl font-bold tracking-tight text-gray-900 sm:text-4xl">
              <span className="block text-white sm:text-lg">Here you can search for apps already tracked by others</span>
              <span className="block text-white sm:text-3xl">Showing results for</span>
               <span className="block text-white">"{searchVal}"</span>
            </h2>
            <div className=" left-0 flex items-center pl-3 pt-4 ">
                <input
                  onChange={(e) => setSearchVal(e.target.value)}
                  value={searchVal}
                  
                  id="search"
                  name="search"
                  className="block w-1/2 mx-auto rounded-md border border-gray-300 bg-white py-2 pl-10 pr-3 h-16 text-sm placeholder-gray-500 focus:border-cyan-500 focus:text-gray-900 focus:placeholder-gray-400 focus:outline-none focus:ring-1 focus:ring-cyan-500 sm:text-sm"
                  placeholder="Search"
                  type="search"
                />
            </div>
      </div>
      {isLoading ? 
      <div className='bg-cyan-700 text-white'>
        <LoadingSpinner padding={"p-12"} textSize={"text-4xl"}></LoadingSpinner>
      </div>
      :
      <div className='bg-white'>
      <div className='grid grid-cols-3 md:grid-cols-5 lg:grid-cols-7 bg-black pt-2'>
        <div className='bg-black text-white col-span-2 font-bold'>Title</div>
        <div className='bg-black text-white col-span-2 hidden md:grid font-bold'>Developer</div>
        <div className='bg-black text-white col-span-2 hidden lg:grid font-bold'>App ID</div>
        <div className='bg-black text-white col-span-1 font-bold'>Dashboard Link</div>
      </div>
      {filteredAppData.length>0 ?

        filteredAppData.map((res) =>{
          return(
          <>
            <div className='grid grid-cols-3 md:grid-cols-5 lg:grid-cols-7 bg-white border-y-2 rounded-md'>
              <a href={res.url} target="_blank" rel="noopener noreferrer" className='bg-white col-span-2 text-cyan-700 font-bold text-left pl-8 lg:pl-16'>{res.title}</a>
              <div className='bg-white col-span-2 text-black font-bold text-left pl-8 lg:pl-16 hidden md:grid'>{res.developer}</div>
              <div className='bg-white col-span-2 text-black font-bold text-left pl-8 lg:pl-16 hidden lg:grid'>{res.appId}</div>
              <Link to={`/singleapp/${res.appId}`} target="_blank" rel="noopener noreferrer" className='bg-white col-span-1 text-black font-bold'>Link</Link>
            </div>
          </>)
        })
      :
      <div className='bg-slate-800 text-white text-3xl py-12 font-bold'>
        No results found
      </div>
      
      
      }

      </div>

      }

      <div className='h-screen bg-cyan-700'>

      </div>

                    




    </>
  )
}

export default SearchSite