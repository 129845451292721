import React from 'react'

function TermsAndPrivacy() {
  return (
    <>
        <div className='bg-green-500 text-white py-12 text-xl fond-bold'>Terms and conditions</div>
        <div className='w-1/2 flex mx-auto text-left'>
            <div className='my-2 text-xl font-black text-black'>Visiting the domain gpAppTracker.com or any of its subdomains or other related sites (hereby referred to as "gpAppTracker") signifies that you agree to all the terms and conditions as stated on this site (hereby referred to as "Terms of Use"). Do not use gpAppTracker if you do not agree.</div>
        </div>
        <div className='w-1/2 mx-auto '>
            <div className='my-2 font-bold text-left'>1. gpAppTracker and its creators takes no responsibility for the correctness and accuracy of the data provided on gpAppTracker </div>
            <div className='my-2 text-left'> Use the available data at your own risk. gpAppTracker will under no circumstance take responsiblity for erroneous data and shall under no circumstances be held liable for losses incurred to users of gpAppTracker due to decisions they make, may it be investment decisions, strategic decisions or other decions based on data available on gpAppTracker.  </div>
            <div className='my-2 text-left'> Users of gpAppTracker must understand that gpAppTracker combines third-party data sources with own algorithms to estimate data. gpAppTracker and its creator cannot guarantee that the data presented is correct or even close to correct. </div>
        </div>
        <div className='w-1/2 mx-auto '>
            <div className='my-2 font-bold text-left'>2. gpAppTracker does not provide you with invest advice </div>
            <div className='my-2 text-left'>Information on gpAppTracker should be viewed and used as information only, and should not under any circumstances be considered to be investment advice.</div>
        </div>
        <div className='w-1/2 mx-auto '>
            <div className='my-2 font-bold text-left'>3. Changes to Terms of Use</div>
            <div className='my-2 text-left'>gpAppTracker reserves its right to, at any time, change the terms of use. Users of gpAppTracker must themselves take responsibility for being in compliance with the Terms of Use</div>
        </div>
        <div className='w-1/2 mx-auto '>
            <div className='my-2 font-bold text-left'>4. Changes to gpAppTracker</div>
            <div className='my-2 text-left'>The content on gpAppTracker may be changed at any time, without notice. gpAppTracker may also be closed down at any time, at the discretion of the creators of gpAppTracker.</div>
        </div>
        <div className='w-1/2 mx-auto '>
            <div className='my-2 font-bold text-left'>5. Copyrights and trademarks</div>
            <div className='my-2 text-left'>gpAppTracker and its copyrights and trademarks, including, but not limited to software, text, documents, photos, graphics, articles and links found on gpAppTracker (hereby referred to as "Content"), are the property of gpAppTracker and its owner, and may not be downloaded, or used against gpAppTracker or its creators' will. gpAppTracker shall be visibly credited if or when Content is republished on social medias or in other medias, or in other usage that is not for solely personal usage. </div>
        </div>
        <div className='w-1/2 mx-auto '>
            <div className='my-2 font-bold text-left'>6. Authentication/Login/Sign In</div>
            <div className='my-2 text-left'>By authenticating, logging in or signing in through any third-party provider, for example Google or Facebook, you accept that gpAppTracker may utilize any information shared from the third-party provider to gpAppTracker.</div>
        </div>
        <div className='w-1/2 mx-auto '>
            <div className='my-2 font-bold text-left'>7. Mail</div>
            <div className='my-2 text-left'>By authenticating, logging in or signing in, you accept that gpAppTracker may, at your own discretion, ask you to let us contact you through your email, and to let gpAppTracker store data about your activity on gpAppTracker</div>
        </div>
        <div className='w-1/2 mx-auto '>
            <div className='my-2 font-bold text-left'>8. Indemnification</div>
            <div className='my-2 text-left'>By using gpAppTracker, you agree that indemnify and hold gpAppTracker and its owner completely harmless from any damages, claims, losses and expenses, including any attorney fees, that directly or indirectly follow from your use of gpAppTracker and/or your violation of Terms of Use</div>
        </div>
        <div className='w-1/2 mx-auto pb-12'>
            <div className='my-2 font-bold text-left'>9. Access Termination </div>
            <div className='my-2 text-left'>By using gpAppTracker, you agree that gpAppTracker can, without notice, limit your access to gpAppTracker. gpAppTracker may also delete your user account or any data affiliated with your user account shall it have reasonable reason to do so, for example if the User is in breach with the Terms of Use. </div>
        </div>
        <div className='bg-green-500 text-white py-12 text-xl fond-bold'></div>


    </>
  )
}

export default TermsAndPrivacy