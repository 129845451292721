import React from 'react'
import { Link } from 'react-router-dom'
import { UserAuth } from '../../context/AuthContext'
import { auth } from '../../config/firebase'
import { db } from '../../config/firebase';
import { useEffect } from 'react';
import { getDoc, getDocs,collection, doc } from 'firebase/firestore';
import { useState } from 'react';
import { QuerySnapshot } from '@firebase/firestore';
import { v4 as uuid } from 'uuid';
import ChartTypeSelection from '../ChartTypeSelection';
import StackedUnstackedSelection from '../StackedUnstackedSelection';
import LoadingSpinner from '../LoadingSpinner';
import SingleChart from '../SingleChart';
import Charts from '../Charts';
import EditDashboardsForm from "../EditDashboardsForm"
import Footer from '../Footer';

function EditDashboards() {
    const {user, logOut} = UserAuth()
    //formsList = navnet på alle forms hos en bruker.
    const [formsList, setFormsList] = useState({})
    const [isLoading, setIsLoading] = useState(true)
    const [selectedDashboard, setSelectedDashboard] = useState("No dashboard selected")
    //
    const [selectedChartType, setSelectedChartType] = useState("")
    //
    const [formsDataList, setFormsDataList] = useState([])
    const [tickerListToPlot, setTickerListToPlot] = useState([])

    const [tickerListFinished, setTickerListFinished] = useState(false)
    //console.log("SELECTEDCHARTTYPE", selectedChartType)
    useEffect(() => {
        const fetchAll = async () => {
            //Finne alle dashboard en bruker (den autentiserte) har
            const data = await getDocs(collection(db, "users",`${user.email}`, "forms"))
            //Lage en "formsList" med alle disse dashboardene.
            setFormsList(data.docs.map((doc) => ({...doc.data(), id: doc.formName})))
            setIsLoading(false)
            }       

        fetchAll()
    },[user.email])

    const handleClick = (form) => async() => {
            setTickerListFinished(false)

            setIsLoading(true)
            setSelectedDashboard(form.formName)
            setIsLoading(false)

          }


    useEffect(() => {
        const getDocFunc = () => {
            
            getDoc(doc(db, "users",`${user.email}`, "forms", selectedDashboard)).then((data) => {
                setFormsDataList(data.data())
                setIsLoading(false)
            })
            }
        getDocFunc()
       //const jw = JSON.parse(listObj)
       //console.log(jw.formName)
    }, [selectedDashboard])

    useEffect(() => {
                    const getTickerListToPlot = () => {
                        const tickerList = []

                        //FIKSE MED IF SÅ IKKE FOR KJØRES MED MINDRE FORMSDATALIST eksisterer
                        if (typeof formsDataList?.formFields !== 'undefined') {
                            for (let i = 0; i < formsDataList?.formFields.length; i++) {
                                //console.log(formsDataList.formFields[i].address)
                                //DENNE LINJEN JEG ENDRET PÅ§!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
                                tickerList.push({address: formsDataList.formFields[i].address, rank: i+1})
                            }
                        setTickerListToPlot(tickerList)
                        setTickerListFinished(true)

                         }
                    }
                    getTickerListToPlot()
    }, [formsDataList])
    

    return (
        <>
                {isLoading ? 
                        <div className='flex justify-between align-center bg-cyan-600 border-t-2 h-screen text-white'><div><LoadingSpinner padding={"p-12"} textSize={"text-4xl"}></LoadingSpinner></div></div>
                    :
                    <div>
                        <div className='flex justify-between align-center bg-cyan-600 border-t-2 '>

                                            <div  className=' text-white text-xl p-1 md:p-4 my-2 hidden md:flex'>
                                             Edit/delete dashboards       
                            </div>
                            <div className='text-white my-2 text-sm lg:text-base grid grid-cols-3 lg:grid-cols-3'>

                                {formsList && formsList.length ? formsList.map((form) => {
                                    //console.log(form.formName)
                                    return(
                                        <div>
                                        <button key={form.formName} onClick={handleClick(form)} className='mx-2 my-1 bg-cyan-700 rounded-md font-medium p-1 md:p-4 w-24 h-16 sm:w-24 md:w-32 lg:w-48 border-white border-2 focus:border-white active:ring-2 active:ring-offset-2 active: ring-teal-500' >{form.formName}</button>
                                        </div>
                                    )
                                    })
                                    :
                                    <div className='m-auto col-span-3'>'</div>
                                }
                            </div>
  
                            <div>
                            </div>


                        </div> 
                        <div className='bg-gray-800 border-t-2 pb-48'>
                            <div className='flex justify-center mx-auto w-full lg:w-1/2 '>

                            <EditDashboardsForm tickers={tickerListToPlot.map(v => ({...v, isActive: true}))} selectedDashboard={selectedDashboard} tickerListFinished={tickerListFinished}></EditDashboardsForm>
                            </div>
                        </div>
                        <Footer></Footer>
                    </div>      
                }

        </>


    )
    }

    export default EditDashboards