import React from 'react'
import { auth,provider,provider2 } from '../config/firebase'
import { signInWithPopup } from 'firebase/auth'
import { Navigate, useNavigate } from 'react-router-dom'
import { UserAuth } from '../context/AuthContext'
import { useEffect } from 'react';
import GoogleButton from 'react-google-button'
import Footer from './Footer';
import {FacebookAuthProvider} from "firebase/auth"


export const Login = () => {
    const navigate = useNavigate()
    const {googleSignIn, user} = UserAuth()

    const signInWithGoogle = async() => {
        try{
            await googleSignIn()
            navigate("/account")
        } catch (error) {
            console.log(error)
        }
    }

/*     const signInWithFacebook = () =>{
        signInWithPopup(auth,provider2).then((res)=>{
            navigate("/account")
        })
        .catch((err)=>{
            console.log(err.message)
        })

    } */
    const signInWithFacebook = async() =>{
        try{
        await signInWithPopup(auth,provider2)
            navigate("/account")
        }catch(error){
            console.log(error)
        }



    }



  return (
    <div>
        <div className="bg-cyan-700 py-8 border-y-2">
        </div>
        <div className="bg-gray-800">
    
            <div className="mx-auto max-w-7xl py-12 px-4 text-center sm:px-6 lg:py-16 lg:px-8">
            {user?.displayName ?
                <>                
                <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
                <span className="block text-white">You are already signed in as {user?.displayName}</span>
                <span className="block text-white text-2xl">({user.email})</span>
                </h2>
                </>

                :
                <>                

                <h2 className="text-2xl font-bold tracking-tight text-gray-900 sm:text-4xl">
                    <span className="block text-white">Sign in with Google to continue</span>
                </h2>
                <div className='py-8 '>
{/*                 <button onClick={signInWithGoogle} className="rounded-md bg-emerald-600 m-1 p-2 text-white text-2xl border-white border-2">Sign in with Google</button>   
 */}                
                    <button onClick={signInWithGoogle} className="w-5/6 md:w-1/2 lg:w-1/4 mx-auto rounded-md bg-cyan-600 m-1 p-2 text-white text-xl font-bold border-white border-2">
                        <div className='flex justify-center'>
                            <div>
                            <svg width="32" height="28" fill="currentColor" viewBox="0 0 18 16"> <path d="M15.545 6.558a9.42 9.42 0 0 1 .139 1.626c0 2.434-.87 4.492-2.384 5.885h.002C11.978 15.292 10.158 16 8 16A8 8 0 1 1 8 0a7.689 7.689 0 0 1 5.352 2.082l-2.284 2.284A4.347 4.347 0 0 0 8 3.166c-2.087 0-3.86 1.408-4.492 3.304a4.792 4.792 0 0 0 0 3.063h.003c.635 1.893 2.405 3.301 4.492 3.301 1.078 0 2.004-.276 2.722-.764h-.003a3.702 3.702 0 0 0 1.599-2.431H8v-3.08h7.545z" fill="white"></path> </svg>
                            </div>
                            <div className='pl-2 my-auto'>
                            Sign in with Google
                            </div>
                        </div>
                    </button>   
                
                
                
                </div>
                <h2 className="text-2xl font-bold tracking-tight text-white sm:text-4xl">
                    <span className="block text-white">or</span>
                </h2>
                <h2 className="text-2xl font-bold tracking-tight text-gray-900 sm:text-4xl">
                    <span className="block text-white">Sign in with Facebook</span>
                </h2>
                <div className='pt-8'>

                <button onClick={signInWithFacebook} className="w-5/6 md:w-1/2 lg:w-1/4  mx-auto rounded-md bg-cyan-600 m-1 p-2 text-white text-xl font-bold border-white border-2">
                    <div className='flex justify-center'>

                        <div className='w-8 h-86 text-white'>
                            <svg fill="currentColor" viewBox="0 0 24 24">
                                <path
                                fillRule="evenodd"
                                d="M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z"
                                clipRule="evenodd"
                                />
                            </svg>
                        </div>
                        <div className='pl-2 my-auto'>
                            Sign in with Facebook
                        </div>
                    </div>
                </button>   
                </div>
                </>

            }
            </div>

        </div>
        <div className="bg-cyan-700 py-24 border-y-2">
        </div>

        <Footer></Footer>

    </div>
  )
}
