import './App.css';
import { Navbar } from './components/Navbar';
import Guide from './components/Guide';
import Home from './components/Home';
import Dashboard from './components/Dashboard';
import CreateDashboard from './components/CreateDashboard';
import Register from './components/Register';
import { Login } from './components/Login';
import { AuthContextProvider } from './context/AuthContext';
import Account from './components/Account';
import Protected from './components/Protected';
import About from './components/paths/About';
import DashboardTopAppsGames from "./components/DashboardTopAppsGames"
import FutureUpdates from "./components/paths/FutureUpdates"
import EditDashboards from './components/paths/EditDashboards';
import {
  BrowserRouter as Router,
  Route,
  Routes,
} from "react-router-dom";
import AppViewMore from './components/AppViewMore';
import TermsAndPrivacy from './components/TermsAndPrivacy';
import SearchSite from './components/SearchSite';
import DashboardSingleChart from './components/DashboardSingleChart';
function App() {
  return (
    <div className="App">
      <AuthContextProvider>
        <Router>
        <Navbar></Navbar>
          <Routes>
              <Route path="/" element={<Home />}/>
              <Route path="/about" element={<About/>}/>
              <Route path="/futureUpdates" element={<FutureUpdates/>}/>
              <Route path="/guide" element={<Guide/>}/>
              <Route path="/dashboard" element={<Dashboard/>}/>
              <Route path="/topApps" element={<DashboardTopAppsGames appOrGame="Apps"/>}/>
              <Route path="/topGames" element={<DashboardTopAppsGames appOrGame="Games"/>}/>
              <Route path="/dashboard/create" element={<CreateDashboard/>}/>
              <Route path="/dashboard/editDashboards" element={<EditDashboards/>}/>
              <Route path="/register" element={<Register/>}/>
              <Route path="/login" element={<Login/>}/>
              <Route path="/account" element={<Protected><Account/> </Protected>}/>
              <Route path="/appinfo/:value" element={<AppViewMore/>}></Route>
              <Route path="/terms" element={<TermsAndPrivacy/>}></Route>
              <Route path="/search" element={<SearchSite/>}></Route>
              <Route path="/singleapp/:value" element={<DashboardSingleChart/>}></Route>
          </Routes>
        </Router>
      </AuthContextProvider>
    </div>
  );
}

export default App;
