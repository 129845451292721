import React from 'react'
import { Link } from 'react-router-dom'
import { UserAuth } from '../context/AuthContext'
import { auth } from '../config/firebase'
import { db } from './../config/firebase';
import { useEffect } from 'react';
import { getDoc, getDocs,collection, doc } from 'firebase/firestore';
import { useState } from 'react';
import { QuerySnapshot } from '@firebase/firestore';
import { v4 as uuid } from 'uuid';
import ChartTypeSelection from './ChartTypeSelection';
import StackedUnstackedSelection from './StackedUnstackedSelection';
import LoadingSpinner from './LoadingSpinner';
import SingleChart from './SingleChart';
import Charts from './Charts';
import EditDashboards from './paths/EditDashboards';
import { ChevronDoubleDown } from 'heroicons-react';
import Footer from './Footer';

function Dashboard() {
    const {user, logOut} = UserAuth()
    //formsList = navnet på alle forms hos en bruker.
    const [formsList, setFormsList] = useState({})
    const [isLoading, setIsLoading] = useState(true)
    const [selectedDashboard, setSelectedDashboard] = useState("")
    //
    const [selectedChartType, setSelectedChartType] = useState("")
    //
    const [formsDataList, setFormsDataList] = useState([])
    const [tickerListToPlot, setTickerListToPlot] = useState([])

    const [fromToDates,setFromToDates] = useState([{startDate: new Date("1/12/1900"),endDate: new Date()}])
    
    const [stackedUnstacked, setStackedUnstacked] = useState("Unstacked")
    useEffect(() => {
        const fetchAll = async () => {
            //Finne alle dashboard en bruker (den autentiserte) har
            if(user?.email){
                const data = await getDocs(collection(db, "users",`${user.email}`, "forms"))
                //Lage en "formsList" med alle disse dashboardene.
                setFormsList(data.docs.map((doc) => ({...doc.data(), id: doc.formName})))
                setIsLoading(false)
                }else{setIsLoading(false)}
             }

        fetchAll()
    },[user?.email])

    const handleClick = (form) => async() => {
            setIsLoading(true)
            setSelectedDashboard(form.formName)
            setIsLoading(false)
            //hente ut data for spesifikt valgt dashboard
            //må lage demo dash som henter fra annet sted

/*                 getDoc(doc(db, "users",`${user.email}`, "forms", selectedDashboard)).then((data) => {
                    console.log("WRITING DATTA")
                    console.log(data.data())
                    setFormsDataList(data.data())
                    console.log("DATA WRITTEN")
                    setIsLoading(false)
                })
            
 */
          }

    const handleDemo = () => () =>{
        setSelectedDashboard("demo")
        setTickerListToPlot([{address:"com.netflix.mediaclient", rank:1}, {address:"com.disney.disneyplus", rank:2},{address:"com.amazon.avod.thirdpartyclient", rank:3},{address:"com.hbo.hbonow", rank:4}])
    }

    useEffect(() => {
        const getDocFunc = () => {
            if(user?.email){
                getDoc(doc(db, "users",`${user.email}`, "forms", selectedDashboard)).then((data) => {
                    setFormsDataList(data.data())
                    setIsLoading(false)
                    

                })
            }else{setIsLoading(false)}
            }
        getDocFunc()
       //const jw = JSON.parse(listObj)
       //console.log(jw.formName)
    }, [selectedDashboard])

    useEffect(() => {
                    const getTickerListToPlot = () => {
                        const tickerList = []

                        //FIKSE MED IF SÅ IKKE FOR KJØRES MED MINDRE FORMSDATALIST eksisterer
                        if (typeof formsDataList?.formFields !== 'undefined') {
                            for (let i = 0; i < formsDataList?.formFields.length; i++) {
                                //console.log(formsDataList.formFields[i].address)
                                //DENNE LINJEN JEG ENDRET PÅ§!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
                                tickerList.push({address: formsDataList.formFields[i].address, rank: i+1})

                            }
                        setTickerListToPlot(tickerList)
                         }
                    }
                    getTickerListToPlot()
    }, [formsDataList])

    function editCreateDashboards(){
        if(user?.email){
            return(
            <>  
            <div>                            
                <Link to="editDashboards">
                    <button className='bg-cyan-500 border-2 rounded-md text-white my-1 mx-2 w-400 h-16 text-sm md:text-base p-1 md:p-4'>Edit existing dashboards</button>
                </Link>
            </div>
            <div>
            <Link to="create">
            <button className='bg-cyan-500 border-2 rounded-md text-white my-1 mx-2 w-400 h-16 text-sm md:text-base p-1 md:p-4'>Create a new dashboard</button>
            </Link>
            </div>
            </>
            )
            
        }else{
            return (
                <>
                <div className='text-white text-xl p-1 md:p-4 my-2 mr-4 hidden md:flex font-bold'>Sign in to create custom dashboards</div>
                </>
            )
        }
    }

    

    return (
        <>
                {isLoading ? 
                        <div className='flex justify-between align-center bg-cyan-900 border-t-2 h-screen text-white'><div><LoadingSpinner padding={"p-12"} textSize={"text-4xl"}></LoadingSpinner></div></div>
                    :
                    <div>
                            <div className='flex justify-between align-center bg-cyan-900 border-t-2 '>

                                            <div  className=' text-white text-xl p-1 md:p-4 my-2 hidden md:flex'>
                                             My dashboards        
                            </div>
                            <div className='text-white my-2 text-sm lg:text-base grid grid-cols-2 xl:grid-cols-3'>
                                <div>
                                    <button onClick={handleDemo()} className='mx-2 my-1 bg-gray-700 rounded-md font-medium p-1 md:p-4 w-24 h-16 sm:w-24 md:w-32 lg:w-48 border-2 border-cyan-600 focus:border-white active:ring-2 active:ring-offset-2 active: ring-cyan-600' >Demo</button>
                                </div>
                                {formsList && formsList.length ? formsList.map((form) => {
                                    //console.log(form.formName)
                                    return(
                                        <div>
                                        <button key={form.formName} onClick={handleClick(form)} className='mx-2 my-1 bg-cyan-700 rounded-md font-medium p-1 md:p-4 w-24 h-16 sm:w-24 md:w-32 lg:w-48 border-2  focus:border-white active:ring-2 active:ring-offset-2 active: ring-cyan-600 overflow-hidden' >{form.formName}</button>
                                        </div>
                                    )
                                    })
                                    :
                                    <div className='m-auto col-span-3'>
                                    </div>
                                }
                            </div>
  
                            <div className='lg:flex my-2'>
                                    {editCreateDashboards()}
                            </div>


                        </div>     
                        <ChartTypeSelection props = {{setSelectedChartType,selectedDashboard, setFromToDates}}/>

                        <StackedUnstackedSelection setStackedUnstacked={setStackedUnstacked} />

                        <Charts tickerListToPlot={tickerListToPlot} selectedChartType={selectedChartType} stackedUnstacked={stackedUnstacked} fromToDates={fromToDates}></Charts>
                        <Footer></Footer>    
                    </div>      
                }

        </>


    )
    }

    export default Dashboard