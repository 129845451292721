import React from 'react'
import { Link } from 'react-router-dom'
import { UserAuth } from '../context/AuthContext'
import app, { auth } from '../config/firebase'
import { db } from '../config/firebase';
import { useEffect } from 'react';
import { getDoc, getDocs,collection, doc } from 'firebase/firestore';
import { useState } from 'react';
import { QuerySnapshot } from '@firebase/firestore';
import { v4 as uuid } from 'uuid';
import ChartTypeSelection from './ChartTypeSelection';
import StackedUnstackedSelection from './StackedUnstackedSelection';
import LoadingSpinner from './LoadingSpinner';
import SingleChart from './SingleChart';
import Charts from './Charts';
import Footer from './Footer';

function DashboardTopAppsGames({appOrGame}) {
    const {user, logOut} = UserAuth()
    //formsList = navnet på alle forms hos en bruker.
    const [formsList, setFormsList] = useState({})
    const [isLoading, setIsLoading] = useState(true)
    const [selectedDashboard, setSelectedDashboard] = useState("Top Free " + appOrGame)
    //
    const [selectedChartType, setSelectedChartType] = useState("")
    //
    const [formsDataList, setFormsDataList] = useState([])
    const [tickerListToPlot, setTickerListToPlot] = useState([])
    const [dbString, setDbString] = useState("trackedAppsTop" + "Free" + appOrGame)

    const [fromToDates,setFromToDates] = useState([{startDate: new Date("1/12/1900"),endDate: new Date()}])


    useEffect(() => {
        const tickerList = []
        const fetchAll = async () => {
            const querySnapshot = await getDocs(collection(db,dbString))
            querySnapshot.forEach((doc) => {
                // doc.data() is never undefined for query doc snapshots
                tickerList.push(doc.data())
                //console.log(doc.id, " => ", doc.data());
              });
              //sortere fra høyeste til laveste rank
              tickerList.sort((a, b) => (a.rank > b.rank) ? 1 : -1)

            //console.log("TICKERLIST", tickerList)
            setTickerListToPlot(tickerList)
            setIsLoading(false)
            }       

        fetchAll()
    },[user?.email, dbString])

    const handleClick = (input) => async() => {
            setDbString("trackedAppsTop" + input + appOrGame)
            setSelectedChartType(input)
            setIsLoading(true)
            setSelectedDashboard("Top " + input + " " + appOrGame)
            setIsLoading(false)
          }


    

    return (
        <>
                {isLoading ? 
                        <div className='flex justify-between align-center bg-cyan-800 border-t-2 h-screen text-white'><div><LoadingSpinner padding={"p-12"} textSize={"text-4xl"}></LoadingSpinner></div></div>
                    :
                    <div>
                        <div className='flex bg-cyan-800 border-t-2'>
                            <div  className=' text-white text-xl p-1 md:p-4 my-2 hidden md:flex'>
                                Categories     
                            </div>
                            <div className='flex m-auto align-center  '>

                            
                                <div className='text-white my-2 text-sm lg:text-base grid grid-cols-3 lg:grid-cols-3'>
                                    <div>
                                        <button onClick={handleClick("Free")} className='mx-2 my-1 bg-gray-700 rounded-md font-medium p-1 md:p-4 w-24 h-16 sm:w-24 md:w-32 lg:w-48 border-2 border-cyan-600 focus:border-white active:ring-2 active:ring-offset-2 active: ring-cyan-500' >Top Free</button>
                                    </div>
                                    <div>
                                        <button onClick={handleClick("Paid")} className='mx-2 my-1 bg-gray-700 rounded-md font-medium p-1 md:p-4 w-24 h-16 sm:w-24 md:w-32 lg:w-48 border-2 border-cyan-600 focus:border-white active:ring-2 active:ring-offset-2 active: ring-cyan-500' >Top Paid</button>
                                    </div>
                                    <div>
                                        <button onClick={handleClick("Grossing")} className='mx-2 my-1 bg-gray-700 rounded-md font-medium p-1 md:p-4 w-24 h-16 sm:w-24 md:w-32 lg:w-48 border-2 border-cyan-600 focus:border-white active:ring-2 active:ring-offset-2 active: ring-cyan-500' >Top Grossing</button>
                                    </div>

                                </div>

                            </div> 
                        </div>    
                        <ChartTypeSelection props = {{setSelectedChartType,selectedDashboard, setFromToDates}}/>
                        <Charts tickerListToPlot={tickerListToPlot} selectedChartType={selectedChartType} stackedUnstacked="Unstacked" fromToDates={fromToDates}></Charts>
                        <Footer></Footer>
                    </div>      
                }

        </>


    )
    }

    export default DashboardTopAppsGames