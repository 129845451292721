import React from 'react'
import { useEffect, useState } from 'react';
import moment from 'moment/moment';
import { getDoc, doc } from 'firebase/firestore';
import { db } from './../config/firebase';
import { useTransition } from 'react';
import Chart from "react-apexcharts"
import { ExclamationTriangleIcon } from '@heroicons/react/20/solid'
import { format, isAfter, isBefore } from 'date-fns';
import { data } from 'autoprefixer';
import {BrowserView, MobileView} from 'react-device-detect';


//Interpolerer et input-array der verdiene eks. er som følger: [100,0,0,115,125]
//Kalles ikke i seg selv, men kalles i "returnInterPolatedArrays"
function interpolateArray(input) {
    let output = [], zeros = 0, start = 0;
    input.forEach(value => {
        if (value) {
            start = start || value;
            if (zeros) {
                let step = (value - start) / (zeros + 1);
                while (zeros--) output.push(start += step);
                zeros = 0;
            }
            output.push(start = value);
        } else zeros++;
    });
    while (zeros--) output.push(start);
    return output;
}
//Fjerner duplikater og interpolerer over en dato-range
function returnInterpolatedArrays(xObj, yObj){

    const findDuplicates = (xObj) => xObj.map((item, index) => {
        if (xObj.indexOf(item) !== index)
            return index;
        }).filter(x => x);  // Equivalent to .filter(x => x != undefined || x != null)
        const xDuplicates = findDuplicates(xObj);
        for (let x of xDuplicates){
            xObj[x] = ""
            yObj[x] = ""
        }
        const yDuplicates = findDuplicates(yObj)
        for (let x of yDuplicates){

            yObj[x] = ""
        }

        let xyObj = xObj.map((x, i) => ({ x, y: yObj[i] }));
        console.log("XYOBJPRE", xyObj)
        xyObj = xyObj.filter(function (e){
            return (e.x !== "" && e.y !== "")
        })

        const l = xyObj.length
        const positions = [0]
        for(var i=0; i<l-1;i++){
            const startDate = moment(xyObj[0].x)
            const endDate = moment(xyObj[i+1].x)
            const days = endDate.diff(startDate, 'd', false);
            positions.push(days)
        }
        //Posisjonene er der det skal mates inn faktiske tall
        //console.log(positions)
        var startDate = moment(xyObj[0].x)
        var endDate = moment(xyObj[xyObj.length-1].x)
        var days = endDate.diff(startDate, 'd', false)
        var xListNew = [] 
        var yListNew = [] 
        for (var i = 0; i <= days; i++) {
            //xListNew.splice(i,0, {"x" : startDate.add(1, 'd')._d.toISOString(), 'y': ""  })
            xListNew.splice(i,0,  startDate.add(1, 'd')._d.toISOString().substring(0, 10))
            yListNew.splice(i,0, 0 )
        }
        for (var i in positions){
            yListNew[positions[i]] = xyObj[i].y
        }
        yListNew = interpolateArray(yListNew)
  
        //Fikse sånn at vi kan ekstrapolere dersom data ikke oppdateres jevnlig (f.eks. ved en uke uten oppdateringer)
        const similarsAtEnd = yListNew.filter(c => c === yListNew[yListNew.length-1]).length;
        //Første argument sjekker at man har nok historikk, andre argument sjekker at det ikke er en død app
        if(yListNew.length>similarsAtEnd+7 && similarsAtEnd<20){
        const increaseRate = (yListNew[yListNew.length-1-similarsAtEnd]-yListNew[yListNew.length-1-similarsAtEnd-7])/7
        for (var k=1;k<=similarsAtEnd;k++){
            //console.log(yListNew[yListNew.length-1-similarsAtEnd+1] + k*increaseRate)
            yListNew[yListNew.length-1-similarsAtEnd+k] = yListNew[yListNew.length-1-similarsAtEnd+1] + k*increaseRate
        } 
        }

        return [xListNew, yListNew]
}
//Subtraherer verdier i en range, slik at man går fra kumulative verdier til daglige verdier
function differenceArrayElements(inputArray) {
    //DEEP COPY KLONE
    const array = structuredClone(inputArray);
    if(array.length>1){
        for (var i = array.length-1; i >0 ; i--){
            array[i] = parseInt(array[i]-array[i-1])

        }
        array[0] = array[1]
    }else{
        array[0]=0
    }
    //console.log("ARRAYFINISHED", array)
    return array

}
//Gir glidende snitt. Fikset slik at de første 7 dagene ikke er glidende, men faktiske tall (for å ikke miste data)
//Brukes for daglige verdier
function sevenDaysMA(inputArray) {
    //Trenger ikke deep copy. 
        var tempArray =  structuredClone(inputArray) 
        for (var i = 0; i <= tempArray.length-1 ; i++){
            if (i==0 && tempArray.length>i+2){
                tempArray[i] = (inputArray[i]+inputArray[i+1]+inputArray[i+2])/3
            }else if(i<6 && inputArray.length>i+1 ){
                tempArray[i] = (inputArray[i]+inputArray[i-1]+inputArray[i+1])/3
            }
            else if(i>=6){
                tempArray[i] = (inputArray[i]+inputArray[i-1]+inputArray[i-2]+inputArray[i-3]+inputArray[i-4]+inputArray[i-5]+inputArray[i-6])/7
            }

        }
    return tempArray

}


function plotStackedChart(xInputArray, yInputArray, title,selectedChartType, yMin, yMax, fromToDates) {


/*     var totalArr = new Array( yInputArray[0].data.length).fill(0);
    for(let element in yInputArray){
        console.log(yInputArray[element].data)
        totalArr = yInputArray[element].data.map((a,i) => a +  totalArr[i])
        console.log("totalArr",totalArr)
        } */


        const historyAvailableFirstDate = new Date(xInputArray[0])
        const historyAvailableLastDate = new Date(xInputArray[xInputArray.length-1])
        var indexCutFirst = -1
        var indexCutLast = -1


        //Handle the last cutoff
        //If last cutoff is after last objectDate, then don't cut off at the end.
        if (isAfter(fromToDates[0].endDate,historyAvailableLastDate)){
            indexCutLast=xInputArray.length
        //Else if last cutoff is before first objectDate, then cut the whole object   
        }else if(isBefore(fromToDates[0].endDate,historyAvailableFirstDate)){
            indexCutLast=0
        //Else The last cutoff is somewhere in between first and last date 
        }else{
            indexCutLast = xInputArray.indexOf(format(fromToDates[0].endDate,"yyyy-MM-dd"))
        }
        //Handle the first cutoff
        //If the first cutoff is before the first objectdate, then don't cut off at the beginning
        if (isBefore(fromToDates[0].startDate,historyAvailableFirstDate)){
            indexCutFirst=0
        //Else if first cutoff is after last objectDate, then cut the whole object  
        }
        else if (isAfter(fromToDates[0].startDate,historyAvailableLastDate)){
            indexCutFirst=0
            indexCutLast=0
        //Else the first cutoff is somewhere in between first and last date
        }else{
            indexCutFirst = xInputArray.indexOf(format(fromToDates[0].startDate,"yyyy-MM-dd"))
        }

        setTimeout(() => {  console.log("!"); }, 5000);

/*         console.log("INDEXCUTFIRTST",indexCutFirst)
        console.log("INDEXCUTLAST",indexCutLast)
        console.log("INPUTARRAYYYYYY",yInputArray)
         */

        var maxVal = 100000
        var minVal = 0
        const dataArr = []
        var yInputArray2 = structuredClone(yInputArray)

        xInputArray = xInputArray.slice(indexCutFirst,indexCutLast+1)


        for (let i=0; i<yInputArray.length;i++){

            if((isAfter(fromToDates[0].startDate,historyAvailableLastDate) && isAfter(fromToDates[0].endDate,historyAvailableLastDate)) || (isBefore(fromToDates[0].startDate,historyAvailableFirstDate) && isBefore(fromToDates[0].endDate,historyAvailableFirstDate))){            
                let [datesListInterpolated, nullListInterpolated] = returnInterpolatedArrays([format(fromToDates[0].startDate,"MM/dd/yyyy"),format(fromToDates[0].endDate,"MM/dd/yyyy")], [0,0])
                xInputArray=datesListInterpolated
                dataArr.push(nullListInterpolated)
                yInputArray2[i].data = nullListInterpolated
            }else{
                //console.log("PAVEL",yInputArray[i].data.slice(indexCutFirst,indexCutLast))
                dataArr.push(yInputArray[i].data.slice(indexCutFirst,indexCutLast))
                yInputArray2[i].data = yInputArray[i].data.slice(indexCutFirst,indexCutLast+1)
            }

        }
        if(yInputArray.length>=1){
        const totalArr = dataArr.reduce((a, b) => a.map((c, i) => c + b[i]));
        minVal = Math.min.apply(-100000000, totalArr)
        maxVal = Math.max.apply(-100000000, totalArr)
        }

        return(
        <>
            <Chart
            type="area"
            height="500"

            series= {yInputArray2} 
            options={{
                title:{text: title + (" (stacked)"), style:{color:"white"}},
                stroke: {
                    curve: 'smooth'
                },
                chart: {
                    height: 500,
                    stacked: true,
                    toolbar: {
                      show: true
                    },
                    zoom: {
                      enabled: true
                    }
                },
                xaxis:{
                    categories: xInputArray, //installDateList
                    labels:{
                        style:{
                            colors:"white"
                        }
                    }
                },
                plotOptions: {
                    bar: {
                      horizontal: false,

                      borderRadius: 0,
                      dataLabels: {
                        orientation: "vertical",
                        maxItems:10,
                        hideOverflowingLabels:true,
                        style: { colors: ["#fff"] },
                        total: {
                            enabled: false,
                            offsetX:-5,
                            formatter: function (value) {
                                //console.log("VALUE", value)
                            if(value<=1000){
                                return parseInt(value).toLocaleString("en-US")}
                            else if(value<=1000000){
                                return parseFloat(value/1000).toLocaleString("en-US",{minimumFractionDigits: 2, maximumFractionDigits: 2})+"k"  
                            }else if(value<=1000000000){
                                return parseFloat(value/1000000).toLocaleString("en-US",{minimumFractionDigits: 2, maximumFractionDigits: 2}) + "mn"
                            }else{
                                return parseFloat(value/1000000000).toLocaleString("en-US",{minimumFractionDigits: 2, maximumFractionDigits: 2}) + "bn"

                            }
                            },
                            style: {
                                color:"white",
                                fontSize: '10px',
                                fontWeight: 500
                            },
                        }
                        }
                    },
                },
                dataLabels:{
                    //True for datalabels
                    enabled: false,
                    //Denne aktiv så slettes datalabels for enkeltbestandelene i barchartet
                    enabledOnSeries: [],
                    textAnchor: "left",

                },


                xaxis:{
                    categories: xInputArray, //installDateList
                    labels:{
                        style:{
                            colors:"white"
                        }
                    }
                },
                legend: {
                    position: 'bottom',
                    offsetY: 0,
                    labels:{colors:"white"}
                  },
                  fill:{
                    type: 'gradient',
                    gradient: {
                        shade:"dark",
                        type: "horizontal",
                        shadeIntensity:0.0,
                        opacityFrom: 0.9,
                        opacityTo: 0.95,
                        stops: [0, 50, 100],
                    }},
                yaxis:{
                    labels:{
                        formatter: function (value) {
                           

                             if(value<1000){
                                return parseFloat(value).toLocaleString("en-US") 
                            }else if (value<1000000){
                                return parseFloat(value/1000).toLocaleString("en-US")+"k"
                            }else if (value<1000000000){
                                return parseFloat(value/1000000).toLocaleString("en-US")+"mn"
                            }else{
                                return parseFloat(value/1000000000).toLocaleString("en-US")+"bn"

                            } 
                        },
                        style:{

                            colors:"white"

                        }
                    }
                },

                
            }}

            ></Chart>

           {/*  <Chart
            type="bar"
            stacked= "true"
            //width={"100%"}
            height={"500"}
            series= {yInputArray2} 
            options={{
                title:{text: title + (" (stacked)"), style:{color:"white"}},
                chart: {
                    height: 350,
                    stacked: true,
                    toolbar: {
                      show: true
                    },
                    zoom: {
                      enabled: true
                    }
                },
                responsive: [{
                breakpoint: 480,

                legend: {
                position: 'bottom',
                offsetX: -10,
                offsetY: 0,
                }
                }],
                plotOptions: {
                    bar: {
                      horizontal: false,

                      borderRadius: 0,
                      dataLabels: {
                        orientation: "vertical",
                        maxItems:10,
                        hideOverflowingLabels:true,
                        style: { colors: ["#fff"] },
                        total: {
                            enabled: true,
                            offsetX:-5,
                            formatter: function (value) {
                                //console.log("VALUE", value)
                            if(value<=1000){
                                return parseInt(value).toLocaleString("en-US")}
                            else if(value<=1000000){
                                return parseFloat(value/1000).toLocaleString("en-US",{minimumFractionDigits: 2, maximumFractionDigits: 2})+"k"  
                            }else if(value<=1000000000){
                                return parseFloat(value/1000000).toLocaleString("en-US",{minimumFractionDigits: 2, maximumFractionDigits: 2}) + "mn"
                            }else{
                                return parseFloat(value/1000000000).toLocaleString("en-US",{minimumFractionDigits: 2, maximumFractionDigits: 2}) + "bn"

                            }
                            },
                            style: {
                                color:"white",
                                fontSize: '10px',
                                fontWeight: 500
                            },
                        }
                        }
                    },
                },
                dataLabels:{
                    //True for datalabels
                    enabled: false,
                    //Denne aktiv så slettes datalabels for enkeltbestandelene i barchartet
                    enabledOnSeries: [],
                    textAnchor: "left",

                },


                xaxis:{
                    categories: xInputArray, //installDateList
                    labels:{
                        style:{
                            colors:"white"
                        }
                    }
                },
                legend: {
                    position: 'bottom',
                    offsetY: 0,
                    labels:{colors:"white"}
                  },
                  fill: {
                    opacity: 0.9
                  },
                yaxis:{
                    labels:{
                        formatter: function (value) {
                           

                             if(value<1000){
                                return parseFloat(value).toLocaleString("en-US") 
                            }else if (value<1000000){
                                return parseFloat(value/1000).toLocaleString("en-US")+"k"
                            }else if (value<1000000000){
                                return parseFloat(value/1000000).toLocaleString("en-US")+"mn"
                            }else{
                                return parseFloat(value/1000000000).toLocaleString("en-US")+"bn"

                            } 
                        },
                        style:{

                            colors:"white"

                        }
                    }
                },

            }}
            >

        </Chart> */}
    </>
    )
}
const options = { labels: ["1 star ", "2 stars", "3 stars", "4 stars", "5 stars"],
title:{text: "", style:{color:"white"}},
colors:["#06b6d4", "#0891b2", "#0e7490", "#075985","#164e63"],
dataLabels: {
    borderRadius:0,
    borderColor: '#18181b',
    foreColor: '#18181b',
    style: {
    fontSize: '12px',

    colors: ["white"],
    padding: 0,

    }},
legend:{
    labels:{
        colors: ["#881337", "#ca8a04", "#facc15", "#a3e635","#111827"]
    }
    },
plotOptions: {
    pie: {
        donut: {
        size: "50%"
        }
    }
    }
}











function StackedChart({slicedTickerListToPlot, selectedChartType,fromToDates}) {
    let firstDate = moment("1/1/3000")
    let lastDate = moment("7/7/1800")

    const [fullRangeDateList, setFullRangeDateList] = useState([])

    const [activeYList, setActiveYList] = useState([])
    const [yMin, setYMin] = useState(0.90)
    const [yMax, setYMax] = useState(1.05)
    const title = selectedChartType
    //SD = stackedDaily, SC=stackedCumulative,   
    const [SDInstallsList, setSDInstallsList] = useState([])
    const [SDRatingsList, setSDRatingsList] = useState([])
    const [SDReviewsList, setSDReviewsList] = useState([])
    
    const [SCInstallsList, setSCInstallsList] = useState([])
    const [SCRatingsList, setSCRatingsList] = useState([])
    const [SCReviewsList, setSCReviewsList] = useState([])

    const [totalVal,setTotalVal] = useState(0)

    useEffect(() => {
        var stackedCumulativeInstallsList = []
        var stackedCumulativeRatingsList = []
        var stackedCumulativeReviewsList = []
        var stackedDailyInstallsList = []
        var stackedDailyRatingsList = []
        var stackedDailyReviewsList = []

        const ObjList = []

        const fetchAll = async () => {
            
            const promises = []
            for (var elem of slicedTickerListToPlot){
                const tickerText = elem.address
                const asyncResult = (await getDoc(doc(db, "trackedAppsDatabase",`${tickerText}`))).data()
                promises.push(asyncResult)
            }
            Promise.all(promises).then(
                promises.forEach((elem) => {
                    const tickerDataObj = elem
                    //console.log("APPPPIIIIIID!",tickerDataObj.appId)
                    if(tickerDataObj===undefined){
                        //console.log("THIS one is undefined", tickerDataObj)
                        return;
                    }
    
                    var [dateList, cumulativeInstallList] = returnInterpolatedArrays(tickerDataObj.maxInstalls.date,tickerDataObj.maxInstalls.installs)
                    var [x, cumulativeRatingsList] = returnInterpolatedArrays(tickerDataObj.maxInstalls.date,tickerDataObj.maxRatings.ratings)
                    var [x, cumulativeReviewsList] = returnInterpolatedArrays(tickerDataObj.maxInstalls.date,tickerDataObj.maxReviews.reviews)
                    var [x, scoreList] = returnInterpolatedArrays(tickerDataObj.maxInstalls.date,tickerDataObj.maxScore.score)
                    
                    var dailyInstallList = sevenDaysMA(differenceArrayElements(cumulativeInstallList))
                    var dailyRatingsList = sevenDaysMA(differenceArrayElements(cumulativeRatingsList))
                    var dailyReviewsList = sevenDaysMA(differenceArrayElements(cumulativeReviewsList))
                    const obj = {title: tickerDataObj.title, developer: tickerDataObj.developer, address: tickerDataObj.appId, date: dateList, cumulativeInstalls: cumulativeInstallList, cumulativeRatings: cumulativeRatingsList, cumulativeReviews: cumulativeReviewsList, scores: scoreList, dailyInstalls: dailyInstallList, dailyRatings: dailyRatingsList, dailyReviews: dailyReviewsList}
                    ObjList.push(obj)
                    const momentFirst = moment(obj.date[0])
                    const momentLast = moment(obj.date[obj.date.length-1])

                    if(momentFirst.isBefore(firstDate,"day")){
                        firstDate=momentFirst
                        //setFirstDate(momentFirst)
                    }
                    if(momentLast.isAfter(lastDate,"day")){
                        lastDate=momentLast
                        //setLastDate(momentLast)
                    }     
                })
            )
                /*slicedTickerListToPlot.forEach(async (elem)=>{
                console.log("ORIGELEM", elem)
                const tickerText = elem.address
                await getDoc(doc(db, "trackedAppsDatabase",`${tickerText}`)).then((docSnap)=>{
                    const tickerDataObj = docSnap.data()


                })
            }) */
        }
        const awaitFetchAll = async (x)=> {
            const y = await fetchAll()
            const dateFromToArray = [firstDate.format("MM/DD/YYYY"),lastDate.format("MM/DD/YYYY")]
            //console.log("datefromArray",dateFromToArray)
            let [dateFromToList, yObjList] = returnInterpolatedArrays( dateFromToArray, [0,1])
            //Interpolerte datoliste er fikset her.
            setFullRangeDateList(dateFromToList)
            //Fikse så all data er interpolert, for deretter å sette rangen som ikke inngår i datefromToList til null.
            ObjList.forEach((item) => {
                let fillArrayCumulativeInstalls =  Array(dateFromToList.length).fill(0)
                let fillArrayCumulativeRatings =  Array(dateFromToList.length).fill(0)
                let fillArrayCumulativeReviews =  Array(dateFromToList.length).fill(0)
                let fillArrayDailyInstalls =  Array(dateFromToList.length).fill(0)
                let fillArrayDailyRatings =  Array(dateFromToList.length).fill(0)
                let fillArrayDailyReviews =  Array(dateFromToList.length).fill(0)
                for (var i=0; i<item.date.length; i++){

                    var indexToFill = dateFromToList.indexOf(item.date[i])
                    fillArrayCumulativeInstalls[indexToFill] = item.cumulativeInstalls[i]
                    fillArrayCumulativeRatings[indexToFill] = item.cumulativeRatings[i]
                    fillArrayCumulativeReviews[indexToFill] = item.cumulativeReviews[i]
    
                    fillArrayDailyInstalls[indexToFill] = item.dailyInstalls[i]
                    fillArrayDailyRatings[indexToFill] = item.dailyRatings[i]
                    fillArrayDailyReviews[indexToFill] = item.dailyReviews[i]

                }
                stackedCumulativeInstallsList.push({name: item.title, data: fillArrayCumulativeInstalls })
                stackedCumulativeRatingsList.push({name: item.title, data: fillArrayCumulativeRatings })
                stackedCumulativeReviewsList.push({name: item.title, data: fillArrayCumulativeReviews })
    
                stackedDailyInstallsList.push({name: item.title, data: fillArrayDailyInstalls })
                stackedDailyRatingsList.push({name: item.title, data: fillArrayDailyRatings })
                stackedDailyReviewsList.push({name: item.title, data: fillArrayDailyReviews })

            })
            setSDInstallsList(stackedDailyInstallsList)   
            setSDRatingsList(stackedDailyRatingsList)   
            setSDReviewsList(stackedDailyReviewsList)
               
            setSCInstallsList(stackedCumulativeInstallsList)   
            setSCRatingsList(stackedCumulativeRatingsList)   
            setSCReviewsList(stackedCumulativeReviewsList)

            if(selectedChartType==="Daily Installs"){
                setActiveYList(stackedDailyInstallsList)
            }
            else if(selectedChartType==="Cumulative Installs"){
                setActiveYList(stackedCumulativeInstallsList)
    
            }
            else if(selectedChartType==="Daily Reviews"){
                setActiveYList(stackedDailyReviewsList)
            }
            else if(selectedChartType==="Cumulative Reviews"){
                setActiveYList(stackedCumulativeReviewsList)
            }
            else if(selectedChartType==="Daily Ratings"){
                setActiveYList(stackedDailyRatingsList)
            }
            else if(selectedChartType==="Cumulative Ratings"){
                setActiveYList(stackedCumulativeRatingsList)
            }
            else if(selectedChartType==="Avg. Rating"){
                
            }

        }
        awaitFetchAll()


    },[slicedTickerListToPlot])

    useEffect(() => {
        //console.log("selectedcharttype:------->", selectedChartType)
        if(selectedChartType==="Daily Installs"){
            setActiveYList(SDInstallsList)
        }
        else if(selectedChartType==="Cumulative Installs"){
            setActiveYList(SCInstallsList)

        }
        else if(selectedChartType==="Daily Reviews"){
            setActiveYList(SDReviewsList)
        }
        else if(selectedChartType==="Cumulative Reviews"){
            setActiveYList(SCReviewsList)
        }
        else if(selectedChartType==="Daily Ratings"){
            setActiveYList(SDRatingsList)
        }
        else if(selectedChartType==="Cumulative Ratings"){
            setActiveYList(SCRatingsList)
        }
        else if(selectedChartType==="Avg. Rating"){
            
        }
    }, [selectedChartType])


  return (
    <>
    <div className='bg-gray-900'>
        <div className='bg-gray-900  lg:w-full xl:w-2/3 mx-auto p-2 rounded-sm'>
            <div className='bg-cyan-900 p-4'>
                <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
{/*                 <span className="block text-white">{title}</span>
 */}                </h2>
                {plotStackedChart(fullRangeDateList, activeYList, title, "", yMin, yMax, fromToDates)}
                
{/*                     <div className="rounded-md bg-rose-400 p-4 mb-2">
                        <div className="flex">
                            <div className="flex-shrink-0">
                            <ExclamationTriangleIcon className="h-5 w-5 text-rose-50" aria-hidden="true" />
                            </div>
                            <div className="ml-3">
                            <div className="mt-0 text-sm text-rose-50">
                                <p>
                                    We are having trouble displaying the stacked chart on mobile devices. Please tilt your screen or use the Desktop Site version of the browser
                                </p>
                            </div>
                            </div>
                        </div>
                    </div> */}
                <div className="rounded-md bg-yellow-50 p-4">
                    <div className="flex">
                        <div className="flex-shrink-0">
                        <ExclamationTriangleIcon className="h-5 w-5 text-yellow-400" aria-hidden="true" />
                        </div>
                        <div className="ml-3">
                        <div className="mt-0 text-sm text-yellow-700">
                            <p>
                                Please note that "AVG. RATING" cannot be selected for stacked charts
                            </p>
                        </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    </>
  )
}

export default StackedChart