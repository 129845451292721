import React from 'react'
import Footer from './Footer';
import Chart from "react-apexcharts"

import { addDays, subDays, eachDayOfInterval, format } from 'date-fns';
import gpAppTrackerLogo from "../assets/images/gpAppTrackerLogo.jpg"

const stats = [
  { id: 1, name: 'Apps already tracked', value: '1000+' },
  { id: 2, name: 'Cost to use', value: '0.00 $' },

]




function Home() {
    function midSection(){
      return(
        <>
            <div className="relative bg-gray-50 ">
            <div className=' h-72 pt-8 pb-4 sm:px-0 sm:pt-16 sm:pb-12 w-full bg-gray-50 object-cover lg:absolute lg:inset-y-0 lg:left-0 lg:h-full lg:w-1/2'>
                <Chart
                type="area"
                height={"100%"}
                
                series={[
                    {
                        name:"",
                        data: [10, 20, 29, 30, 50, 60, 75, 100, 135, 180, 250, 350,470,670, 890, 1337]
                    }   //data: [{ x: 't', y: 54 },{ x: 't+1', y: 57 },{ x: 't+2', y: 81 },{ x: 't+3', y: 79 },{ x: 't+4', y: 84 },{ x: 't+5', y: 124 },{ x: 't+6', y: 180 },{ x: 't+7', y: 132 },{ x: 't+8', y: 249 },{ x: 't+9', y: 246 },{ x: 't+10', y: 228 },{ x: 't+11', y: 390 }, { x: 't+12', y: 632 } , { x: 't+13', y: 921 },{ x: 't+14', y: 1290 } ,]                }
                ]}
                options={{
                    chart:{toolbar:{show:false}},

                  title:{text:["Your Favorite App - Daily Downloads"], style:{color:"#334155", fontSize: "20px"}},
                  colors:["#3f3f46"],
                  responsive:[
                    {
                      breakpoint: 420,
                      options:{
                        title:{text:["Your Favorite App - Daily Downloads"], style:{color:"#334155", fontSize: "12px"}},

                      }

                    }
                  ],
                    dataLabels: {
                      enabled: false,
                        style: {
                          colors: ["#334155"]
                        }
                      },
                      xaxis:{
                        categories: eachDayOfInterval({start:  subDays(new Date(),15), end: new Date()}).map(e => format(e, "yyyy-MM-dd")),
                        labels:{
                          show:false,
                            style:{
                                colors:"#334155"
                            }
                        }
                      },
                      yaxis:{
                        show:false,
                        labels:{
                          show:false,

                        }
                      },
                      grid:{
                        show:false,
                        borderColor:"#FFFFFF",
                      }
                }}
                >

                </Chart>
            </div>
            <div className="mx-auto grid max-w-7xl lg:grid-cols-2 ">
              <div className="px-6 pt-16 pb-24 sm:pt-10 sm:pb-16 lg:col-start-2 lg:px-8 lg:pt-16">
                <div className="mx-auto max-w-2xl lg:mr-0 lg:max-w-lg">
                  <h2 className="text-base font-semibold leading-8 text-cyan-700">Why use us?</h2>
                  <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
                    Our site is getting increasingly more popular every day
                  </p>
                  <p className="mt-6 text-lg leading-8 text-gray-600">
                    Do as so many others - use our site to track app statistics from Google Play
                  </p>
                  <p className="text-lg mt-2 font-bold leading-8 text-gray-600">
                      You can use this site for instance to:              
                  </p>
                  <p className=" text-lg leading-8 text-gray-600">
                      - Spy on competitors' apps              
                  </p>
                  <p className="text-lg  leading-8 text-gray-600">
                      - Use app statistics, such as download statistics, to trade stocks            
                  </p>
                  <p className="text-lg leading-8 text-gray-600">
                      - Follow apps simply due to an interest in doing so            
                  </p>
                  <dl className="mt-16 grid max-w-xl grid-cols-1 gap-8 sm:mt-20 sm:grid-cols-2 xl:mt-16">
                    {stats.map((stat) => (
                      <div key={stat.id} className="flex flex-col gap-y-3 border-l-4 border-gray-900/10 pl-6">
                        <dt className="text-sm leading-6 text-gray-600">{stat.name}</dt>
                        <dd className="order-first text-3xl font-semibold tracking-tight text-gray-900">{stat.value}</dd>
                      </div>
                    ))}
                  </dl>
                </div>
              </div>
            </div>
          </div>
        
        </>
      )
    }
  



  return (
    <div>
        <div className="bg-cyan-700 border-t-2">
        <div className="mx-auto max-w-7xl py-12 px-4 text-center sm:px-6 lg:py-16 lg:px-8">
            <h2 className="text-xl font-bold tracking-tight text-gray-900 sm:text-4xl">
              <span className="block text-white">Do you want to track app downloads, reviews and ratings completely free of charge?</span>
              <span className="block text-white text-base sm:text-xl"> - Search for single apps without any need for registration</span>
              <span className="block text-white text-base sm:text-xl"> - Register to create your own custom dashboards consisting of multiple apps</span>
            </h2>
            <div className="mt-8 flex justify-center">
            <div className="inline-flex rounded-md shadow">
                <a
                href="/guide"
                className="inline-flex items-center justify-center rounded-md border border-transparent bg-cyan-900 px-5 py-3 text-base font-medium text-white hover:bg-gray-100 hover:text-black"
                >
                Get started
                </a>
            </div>
            <div className="ml-3 inline-flex">
                <a
                href="/about"
                className="inline-flex items-center justify-center rounded-md border border-transparent bg-slate-700 px-5 py-3 text-base font-medium text-white hover:bg-white hover:text-black"
                >
                Learn more
                </a>
            </div>
            </div>
        </div>
        <div>
          {midSection()}
        </div>
        </div>
{/*         <div className="bg-cyan-700 border-white border-b-2 border-t-2">
          <div className="mx-auto max-w-7xl py-12 px-4 text-center sm:px-6 lg:py-16 lg:px-8 border-b-0">
            <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
                <span className="block text-customWhite text-xl sm:text-2xl">Keep track of your favorite apps - updated daily</span>
            </h2>
              <Chart
              type="bar"
              width={"100%"}
              height={200}
              
              series={[
                  {
                      name:"XYZ streaming App",
                      data: [650, 680, 695, 700, 800, 935, 922, 1100, 1200, 1490, 1932, 1945,2245,2700, 3400, 5000]
                  }   //data: [{ x: 't', y: 54 },{ x: 't+1', y: 57 },{ x: 't+2', y: 81 },{ x: 't+3', y: 79 },{ x: 't+4', y: 84 },{ x: 't+5', y: 124 },{ x: 't+6', y: 180 },{ x: 't+7', y: 132 },{ x: 't+8', y: 249 },{ x: 't+9', y: 246 },{ x: 't+10', y: 228 },{ x: 't+11', y: 390 }, { x: 't+12', y: 632 } , { x: 't+13', y: 921 },{ x: 't+14', y: 1290 } ,]                }
              ]}
              options={{
                  chart:{toolbar:{show:false}},

                  title:{text:"XYZ Streaming App - daily downloads", style:{color:"#FFFFFF"}},
                  colors:["#3f3f46"],
                  
                  dataLabels: {
                    enabled: false,
                      style: {
                        colors: ["#FFFFFF"]
                      }
                    },
                    xaxis:{
                      categories: eachDayOfInterval({start:  subDays(new Date(),15), end: new Date()}).map(e => format(e, "yyyy-MM-dd")),
                      labels:{
                          style:{
                              colors:"#FFFFFF"
                          }
                      }
                    },
                    yaxis:{
                      labels:{
                          style:{
                              colors:"#FFFFFF"
                          }
                      }
                    },
                    grid:{
                      show:true,
                      borderColor:"#FFFFFF",
                    }

              }}
              >

              </Chart>
          </div>
        </div> */}
        <Footer></Footer>
    </div>


  )
}

export default Home