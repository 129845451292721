import React, { useState, useEffect } from 'react'

import { WindowIcon, XMarkIcon } from '@heroicons/react/20/solid'
import {db} from "../config/firebase"
import { collection, addDoc, setDoc, doc, deleteDoc} from "firebase/firestore"
import { auth } from '../config/firebase';
import { XCircleIcon, ArrowUturnUpIcon, ExclamationTriangleIcon } from '@heroicons/react/20/solid'

import SuccessModal from './SuccessModal';
import SuccessDeleteModal from './SuccessDeleteModal';



function EditDashboardsForm({tickers, selectedDashboard, tickerListFinished}) {

    const [formName, setFormName] = useState("")
    const [tickerLimitAlert, setTickerLimitAlert] = useState(false)
    const [successfulSubmit, setSuccessfulSubmit] = useState("INITSTATE")
    const [successfulDelete, setSuccessfulDelete] = useState("INITSTATE")
    const [updateCount, setUpdateCount] = useState(0)

    const [totalTickers, setTotalTickers] = useState(0)

    //Legger til en verdi, isActive i alle objektene i arrayet

    //oldTickers.push({address: "com.nan", rank:"0", isActive:true})
    const [oldTicks, setOldTicks] = useState([])

    //console.log("oldTicks", oldTicks)
    
    useEffect(() => {
        //tickers =  tickers.map(v => ({...v, isActive: true}))
        setOldTicks(tickers)
    }, [tickerListFinished])

    useEffect(() => {
        //tickers =  tickers.map(v => ({...v, isActive: true}))
        setFormName(selectedDashboard)
    }, [selectedDashboard])



    const [formFields, setFormFields] = useState([
        {address: "",
        //appName: "",
        //companyName: ""
        }
    ])
    const handleFormChange = (e, index) => {
        let data = [...formFields]
        data[index][e.target.name] = e.target.value
        setFormFields(data)
        //console.log(formFields)
    }
    

const addFields = () => {

    //Teller antall instanser i oldTicks der isActive er true
    const oldFieldsCount = oldTicks.filter((obj) => obj.isActive === true).length
    if((formFields.length + oldFieldsCount)>=30){
        setTickerLimitAlert(true)
    }else{
        setTickerLimitAlert(false)
        let object = {
            address: "",
        }
        setFormFields([...formFields, object])
    }  
}

    const removeFields = (index) => {

        let data = [...formFields]
        data.splice(index, 1)
        setFormFields(data)
    }

    const handleSubmit = async(e) => {
        e.preventDefault()
        //For å skyve data inn i database
        
        //Filtrere ut de som ikke er satt som aktive i de "gamle" appene
        const filteredArray = oldTicks.filter(obj => obj.isActive !== false)
        //Fjerne attributtene isActive og rank fra oldTicks (for i neste steg å slå det sammen med formFields)
        const filteredActivesArray = filteredArray.map(({rank, isActive, ...item}) => item)
        //Slå sammen formFields og filteredActivesArray
        const combinedArray = filteredActivesArray.concat(formFields)
        //Fjerne eventuelle blanks
        const filteredCombinedArray = combinedArray.filter(obj => obj.address !== "")

        try{
            await setDoc(doc(db, "users",auth.currentUser.email, "forms", formName), {
                formName: formName,
                formFields: filteredCombinedArray
                
            })
            setSuccessfulSubmit(true)


        } catch {
            setSuccessfulSubmit(false)
        }
        //For å skyve inn i database for apper man tracker.
        filteredCombinedArray.map(async(e) => {
            //console.log(e.address)
            try{

                await setDoc(doc(db, "trackedApps",e.address), {
                    address: e.address
                })
                setSuccessfulSubmit(true)

    
            } catch {
                setSuccessfulSubmit(false)


            }
        })
        setUpdateCount(updateCount+1)
    }
    

    function doSomething(e,index){
        //let data = [...tickers]
        if(tickers[index].isActive ===false){

            //sjekke at bruker ikke har lagt inn for mange apper
            const oldFieldsCount = oldTicks.filter((obj) => obj.isActive === true).length
            const newFieldsCount =  formFields.length
            //console.log("OLDNEWFIELDSCOUNT", oldFieldsCount+newFieldsCount)
            if(oldFieldsCount+newFieldsCount<30){
                tickers[index].isActive = true
            }

        } else{
            tickers[index].isActive = false
        }
        //data[index].isActive = !data[index].isActive
        //tickers[index].isActive= false

        //Må legge inn [...tickers] for at setOldTicks skal tvinge rerendering --> Tror den får inn en ny kompoennt.
        setOldTicks([...tickers])

 
    }

    //Funksjon som oppdaterer navnet på hvilket form som er valgt
    function updateFormName(e){
        //Dersom navn er av lengde null så settes det til form-navnet man har klikket på (i.e. navn uendret)
        if(e.length===0){
            setFormName(selectedDashboard)
        }else{
            setFormName(e)
        }
    }


    //Egt. bare front-end-kode som er lagt inn i funksjon for ryddighet. Denne gir et varsel om at man har makset ut antall apper man kan ha i et dashboard.
    function warningModalOldApps(){
        const oldFieldsCount = oldTicks.filter((obj) => obj.isActive === true).length
        const newFieldsCount =  formFields.length
        if(oldFieldsCount+newFieldsCount>=30){
        return (
            <div className="rounded-md bg-red-50 p-4 m-4">
                <div className="flex">
                    <div className="flex-shrink-0">
                        <XCircleIcon className="h-5 w-5 text-red-400" aria-hidden="true" />
                    </div>
                    <div className="ml-3">
                        <h3 className="text-sm font-medium text-red-800">There is currently a limit on apps you can track per form</h3>
                        <div className="mt-2 text-sm text-red-700">
                        <ul role="list" className="list-disc space-y-1 pl-5">
                            <li>Apps per form is currently limited to 30, due to performance issues</li>
                            <li>You can track more apps by making more dashboards</li>
                        </ul>
                        </div>
                    </div>
                </div>
            </div>
        )
        }
    }

    //Funksjon som sletter dashboard. Inkludert er setting av state'en successfulDelete, som styrer om modal popper opp
    const deleteDashboard = async() =>{
        //Dersom man ikke har valgt noe dashboard så exit'er man bare funksjonen
        if(formName==="No dashboard selected"){return}
        try{
                await deleteDoc(doc(db, "users",auth.currentUser.email, "forms", formName)).then(
                        setSuccessfulDelete(true)
                ).catch((error) => {
                    console.log(error)
                    setSuccessfulDelete(false)

                })
            


        } catch {
            console.log("NOE GIKK GALT 1")
        }
        setUpdateCount(updateCount+1)

    }


    return (

    <>
        <div className='w-full mt-2 mb-4 p-2 border-2 border-cyan-600 h-screen/2'>

        <div className='p-2 mt-2 '>
        <div className='p-1 bg-cyan-900 rounded'>

        <form>
                <div className='mt-4 text-3xl px-4 p-2 bg-cyan-900 rounded-md text-white w-full font-bold'>{selectedDashboard}</div>
                <div className='bg-cyan-900 p-2'>
                    <div className=" sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-5 pb-2 sm:border-y sm:border-gray-200">
                            <label htmlFor="first-name" className="block text-sm font-medium text-white sm:mt-px sm:pt-2">
                                Form name
                            </label>
                            <div className="mt-1 sm:col-span-2 sm:mt-0">
                                <input
                                type="text"
                                name="first-name"
                                id="first-name"
                                autoComplete="given-name"
                                placeholder="(Keep blank to keep unchanged)"
                                onChange={(e) => updateFormName(e.target.value)}

                                className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-cyan-500 focus:ring-cyan-500 sm:text-sm"
                                />
                            </div>
                    </div>
 
                </div>
                {/* Seksjon med allerede eksisterende tickere */}
                <div className='text-white text-lg font-bold'>Edit existing apps in dashboard</div>
                {warningModalOldApps()}
                {oldTicks.map((elem, index) => {
                    return (
                        <div key={index} className="pt-1">
                            {elem.isActive===true ?  
                                            <div className='flex w-5/6 lg:w-2/3 justify-center mx-auto bg-cyan-700 border-2 mt-1 rounded'> 
                                                <div className='grid-cols-1 w-3/4 mt-2'>
                                                    <label htmlFor="" className="p-2 text-m my-auto font-medium text-white">
                                                                Link # {index + 1} 
                                                    </label>
                                                    <div className='grid grid-cols-1 xl:grid-cols-3 justify-between '>


                                                        <div className="relative p-2 rounded-md shadow-sm xl:col-span-3">
                                                            <div className="absolute  left-0 flex items-center pl-3 my-auto">
                                                                <WindowIcon className="h-8 w-8 text-cyan-400 hidden sm:visible" aria-hidden="true" />
                                                            </div>
                                                            <div className='text-cyan-400 font-bold'>
                                                                {elem.address} 
                                                            </div>
                                                        </div>
                
                                                    </div>

                                                    <div className='p-2 my-auto '>
                                                        <button type="button" className='bg-white rounded-md p-1 w-full' onClick={(elem) => doSomething(elem,index)} ><XMarkIcon className='h-6 w-6 text-red-400 m-auto' /></button>
                                                    </div>
                                                </div>
                                            </div>
                            : 
                                            <div className='flex w-5/6 lg:w-2/3 justify-center mx-auto bg-gray-500 border-2 mt-1 rounded opacity-75'> 
                                                <div className='grid-cols-1 w-3/4 mt-2'>
                                                    <label htmlFor="" className="py-2 text-m my-auto font-medium text-white">
                                                                Link # {index + 1}
                                                    </label>
                                                    <div className='grid grid-cols-1 xl:grid-cols-3 justify-between '>


                                                        <div className="relative py-2 rounded-md shadow-sm xl:col-span-3">
                                                            <div className=" absolute  left-0 flex items-center pl-3 my-auto">
                                                                <WindowIcon className="h-8 w-8 text-cyan-400 hidden sm:visible" aria-hidden="true" />
                                                            </div>
                                                            <div className='text-cyan-400 font-bold'>
                                                                {elem.address} 
                                                            </div>
                                                        </div>

                                                    </div>

                                                    <div className='py-2 my-auto '>
                                                        <button type="button" className='bg-white rounded-md p-1 w-full' onClick={(elem) => doSomething(elem,index)}><ArrowUturnUpIcon className='h-6 w-6 text-cyan-400 m-auto' /><div className='text-cyan-400 font-bold'>Add back</div></button>
                                                    </div>
                                                </div>
                                            </div>   
                            }
                        </div>
                    )
                })}

                {/* Seksjon med å legge til evt nye*/}
                <div className='text-white text-lg font-bold border-t-2 mt-2 pt-2'>Add new apps to the dashboard</div>

                {formFields.map((form, index)=>{
                    return(
                    <div key={index} className="pt-1">
                        <div className='flex w-5/6 lg:w-2/3 justify-center mx-auto bg-cyan-700 border-2 mt-1 rounded'> 
                                <div className='grid-cols-1 w-3/4 mt-2'>
                                    <label htmlFor="" className="p-2 text-m my-auto font-medium text-white">
                                                Link # {index + 1}
                                    </label>
                                    
                                        <div className='grid grid-cols-1 xl:grid-cols-3 justify-between '>


                                            <div className="relative py-2 rounded-md shadow-sm xl:col-span-3">
                                                <div className=" absolute left-0 hidden sm:flex  items-center pl-3 my-auto ">
                                                    <WindowIcon className="h-8 w-8 text-gray-400" aria-hidden="true" />
                                                </div>
                                                <input
                                                type="address"
                                                name="address"
                                                id="address"
                                                className="block w-full rounded-md border-gray-300 pl-2 sm:pl-12 focus:border-cyan-500 focus:ring-cyan-500 p-1"
                                                placeholder="e.g. 'com.netflix.mediaclient'"
                                                onChange={e => handleFormChange(e,index)} value={form.name}
                                                />
                                            </div>
                                        </div>

                                    <div className='py-2 my-auto '>
                                        <button className='bg-white rounded-md p-1 w-full' onClick={() => removeFields(index)}><XMarkIcon className='h-6 w-6 text-red-400 m-auto' /></button>
                                    </div>
                                </div>
                        </div>
                    </div>
                    )
                })}

        </form>
                <div className='flex flex-col justify-center items-center'>
                    
                    {warningModalOldApps()}
                    <div className='w-1/2 lg:w-1/2  flex flex-col justify-center items-center'>
                        <button onClick={addFields} className="p-2 m-2 bg-gray-800 rounded border-2 border-cyan-600 text-white w-full">Add more fields</button>
                        <button onClick={handleSubmit} className="p-2 m-2 bg-gray-800 rounded border-2 border-cyan-600 text-white w-full">Submit and confirm changes</button>
                        <button onClick={deleteDashboard}  className="p-2 m-2 bg-rose-600 rounded border-2 border-rose-300 text-white w-full">Delete dashboard</button>
                        <SuccessModal updateCount={updateCount} successBool = {successfulSubmit}></SuccessModal>
                        <SuccessDeleteModal updateCount={updateCount} successBool = {successfulDelete}></SuccessDeleteModal>

                    </div>
                </div>
                
            </div>
        </div>


        
        
        
        </div>
    
    
    </>
  )
}

export default EditDashboardsForm