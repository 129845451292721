import React from 'react'
import { Link } from 'react-router-dom'
import { UserAuth } from '../context/AuthContext'
import { auth } from '../config/firebase'
import { db } from './../config/firebase';
import { useEffect } from 'react';
import { getDoc, getDocs,collection, doc } from 'firebase/firestore';
import { useState } from 'react';
import { QuerySnapshot } from '@firebase/firestore';
import { v4 as uuid } from 'uuid';
import ChartTypeSelection from './ChartTypeSelection';
import StackedUnstackedSelection from './StackedUnstackedSelection';
import LoadingSpinner from './LoadingSpinner';
import SingleChart from './SingleChart';
import Charts from './Charts';
import EditDashboards from './paths/EditDashboards';
import { ChevronDoubleDown } from 'heroicons-react';
import Footer from './Footer';
import { useParams } from 'react-router-dom';

function DashboardSingleChart() {
    const appId = useParams().value
    const {user, logOut} = UserAuth()
    //formsList = navnet på alle forms hos en bruker.
    const [formsList, setFormsList] = useState({})
    const [isLoading, setIsLoading] = useState(true)
    const [selectedDashboard, setSelectedDashboard] = useState("")
    //
    const [selectedChartType, setSelectedChartType] = useState("")
    //
    const [tickerListToPlot, setTickerListToPlot] = useState([{address: appId, rank:1}])

    const [fromToDates,setFromToDates] = useState([{startDate: new Date("1/12/1900"),endDate: new Date()}])
    
    const [stackedUnstacked, setStackedUnstacked] = useState("Unstacked")

    useEffect(() => {
      const fetchAll = async () => {
          //Finne alle dashboard en bruker (den autentiserte) har
          setTickerListToPlot(appId)  
      fetchAll()
      }},[appId])




    return (
        <>

                    <div>
   
                        <ChartTypeSelection props = {{setSelectedChartType,selectedDashboard, setFromToDates}}/>


                        <Charts tickerListToPlot={tickerListToPlot} selectedChartType={selectedChartType} stackedUnstacked={stackedUnstacked} fromToDates={fromToDates}></Charts>
                        <Footer></Footer>    
                    </div>      
                

        </>


    )
    }

    export default DashboardSingleChart